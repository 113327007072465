import React, { useCallback, useMemo } from 'react';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import type {
	FieldMode,
	AvailableField,
} from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-layout.tsx';
import { SuccessFlagWithBoardLink } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/success-flag-with-board-link/index.tsx';
import { PLAN_MODE } from '../../../common/constants/card-layout.tsx';
import { useAddCardLayoutField } from '../../../services/add-card-layout-field/index.tsx';
import { useIsScrumBoard, useRapidViewId } from '../../selectors/board/index.tsx';
import { useCardLayoutStoreActions } from '../../selectors/card-layout/index.tsx';
import messages from './messages.tsx';

export const useAddCardLayoutFieldHandler = (fieldMode: FieldMode) => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();

	const [rapidViewId] = useRapidViewId();
	const [isScrumBoard] = useIsScrumBoard();
	const [, { addCurrentField }] = useCardLayoutStoreActions();

	const { commit: commitAddCardLayoutField, loading: isAddSubmitting } = useAddCardLayoutField(
		rapidViewId,
		fieldMode,
	);

	const fieldLocation = useMemo(() => {
		if (fieldMode === PLAN_MODE) {
			return messages.addFieldSuccessBacklogTitle;
		}
		return isScrumBoard
			? messages.addFieldSuccessActiveSprintsTitle
			: messages.addFieldSuccessKanbanBoardTitle;
	}, [fieldMode, isScrumBoard]);

	const commitFieldAdd = useCallback(
		async (field: AvailableField) => {
			const result = await commitAddCardLayoutField(field);

			if (result) {
				addCurrentField(result);
				showFlag((props) => (
					<SuccessFlagWithBoardLink
						{...props}
						title={formatMessage(fieldLocation, {
							fieldName: field.name,
						})}
						isCMPBoard
					/>
				));
			}
		},
		[addCurrentField, commitAddCardLayoutField, formatMessage, fieldLocation, showFlag],
	);

	return { commitFieldAdd, isAddSubmitting };
};
