import React, { useState } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { ErrorAutoDismissFlag } from '@atlassian/jira-flags/src/common/ui/components'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { useProjectSidebarNavigation } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/project-sidebar-navigation-resource/index.tsx';
import { useUpdateBoardName as useUpdateBoardNameState } from '@atlassian/jira-software-board-settings-general-common/src/controllers/main.tsx';
import { useBoardName as useBoardNameState } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board-fields/index.tsx';
import {
	useRapidViewId,
	useIsCMPBoard,
} from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board/index.tsx';
import { useSettingsContainerContext } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/controllers/use-settings-container-context/index.tsx';
import { useFireAnalyticsTrackEvent } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-fire-analytics-event/index.tsx';
import { useIsJSMBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-jsm-board/index.tsx';
import { SuccessFlagWithBoardLink } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/success-flag-with-board-link/index.tsx';
import { useUpdateRemoteBoardName } from '../../services/update-remote-board-name/index.tsx';
import messages from './messages.tsx';

export const useBoardName = () => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();
	const isCMPBoard = useIsCMPBoard();
	const rapidViewId = useRapidViewId();
	const boardName = useBoardNameState();
	const updateLocalBoardName = useUpdateBoardNameState();
	const { updateBoardName: updateSettingsContainerBoardName } = useSettingsContainerContext();

	const { commit: updateRemoteBoardName } = useUpdateRemoteBoardName();
	const { refresh: refreshSidebar } = useProjectSidebarNavigation();
	const [nextBoardName, setNextBoardName] = useState<string | null>(null);
	const isJSMBoard = useIsJSMBoard();
	const { fireTrackEvent } = useFireAnalyticsTrackEvent();

	const updateHeaderName = (name: string) => {
		// update the header text, using document because the header is still under the legacy UI

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const navBoardName = document.getElementById('js-nav-view-name');
		if (navBoardName != null) {
			navBoardName.innerText = name;
		}
	};
	const onUpdate = async (newBoardName: string) => {
		setNextBoardName(newBoardName);
		if (boardName === newBoardName) return;

		const result = await updateRemoteBoardName({
			id: rapidViewId,
			name: newBoardName,
		});

		if (result && !result.errors) {
			updateLocalBoardName(newBoardName);
			if (!isJSMBoard) {
				// JSM Board don't have the board name breadcrumb
				refreshSidebar();
				// JSW no longer uses legacy header
				fg('simplified-boards-milestone-2_07hbk') && updateSettingsContainerBoardName(newBoardName);
			}
			updateHeaderName(newBoardName);
			showFlag((props) => (
				<SuccessFlagWithBoardLink
					isCMPBoard={isCMPBoard}
					title={formatMessage(messages.updateBoardNameSuccessfulTitle)}
					{...props}
				/>
			));
			fireTrackEvent('updateBoardName succeeded');
		} else {
			showFlag((props) => (
				<ErrorAutoDismissFlag
					title={formatMessage(messages.updateBoardnameFailureTitle)}
					description={formatMessage(messages.updateBoardnameFailureDescription)}
					{...props}
				/>
			));
			fireTrackEvent('updateBoardName failed');
		}
		setNextBoardName(null);
	};

	return {
		name: nextBoardName ?? boardName ?? '',
		onUpdate,
	};
};
