import { useMemo } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import type { ConfigParamKey } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';

const needsEditModelSet: Set<ConfigParamKey> = new Set([
	'cardColors',
	'cardLayout',
	'estimation',
	'filter',
	'quickFilters',
	'swimlanes',
	'timelineConfig',
]);

export const useIsNeedsEditModel = (config: ConfigParamKey | null) =>
	useMemo(() => {
		if (!config) {
			return false;
		}

		return fg('board-settings-graphql-refactor_cyvz9') ? false : needsEditModelSet.has(config);
	}, [config]);
