import React, { type RefObject, useCallback, useMemo, useState, useRef } from 'react';
import { styled } from '@compiled/react';
import Button, { LoadingButton } from '@atlaskit/button';
import DragHandlerIcon from '@atlaskit/icon/glyph/drag-handler';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import { Box, Grid, xcss } from '@atlaskit/primitives';
import Select from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type {
	AvailableField,
	FieldMode,
} from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-layout.tsx';
import { useFireAnalyticsTrackEvent } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-fire-analytics-event/index.tsx';
import { dataTestIds } from '../../../../common/constants/data-test.tsx';
import { tick } from '../../../../common/utils/index.tsx';
import { useAddCardLayoutFieldHandler } from '../../../../controllers/handlers/add-card-layout-field-handler/index.tsx';
import { useAddFieldFocusHandler } from '../../../../controllers/handlers/global-focus-handler/index.tsx';
import { useAvailableFields } from '../../../../controllers/selectors/card-layout/index.tsx';
import messages from './messages.tsx';

export const AddField = ({ fieldMode }: { fieldMode: FieldMode }) => {
	const [isCreating, setIsCreating] = useState(false);
	const [value, setValue] = useState<AvailableField | null>(null);
	const [availableFields] = useAvailableFields();
	const { addFieldRef } = useAddFieldFocusHandler(fieldMode);
	const { fireTrackEvent } = useFireAnalyticsTrackEvent();

	const { commitFieldAdd, isAddSubmitting } = useAddCardLayoutFieldHandler(fieldMode);

	const fields = useMemo(
		() => availableFields.filter((f) => f.mode === fieldMode),
		[fieldMode, availableFields],
	);

	const onSubmitInternal = useCallback(async () => {
		if (value == null) return;
		await commitFieldAdd(value);
		setIsCreating(false);
		setValue(null);
		fg('jsw_due_date_metrics')
			? fireTrackEvent('boardCardLayout addField', { fieldName: value.name, fieldMode })
			: fireTrackEvent('boardCardLayout addField');
	}, [value, commitFieldAdd, fireTrackEvent, fieldMode]);

	return (
		<AddFieldPure
			value={value}
			onSubmit={onSubmitInternal}
			onChange={setValue}
			availableFields={fields}
			isCreating={isCreating}
			setIsCreating={setIsCreating}
			isSubmitting={isAddSubmitting}
			addFieldRef={addFieldRef}
		/>
	);
};

export const AddFieldPure = function AddFieldPure<T extends { name: string; fieldId: string }>({
	addFieldRef,
	availableFields,
	isCreating,
	isSubmitting,
	value,
	setIsCreating,
	onChange,
	onSubmit,
}: {
	addFieldRef?: RefObject<HTMLElement>;
	availableFields: readonly T[];
	isCreating: boolean;
	isSubmitting: boolean;
	value: T | null;
	setIsCreating: (value: boolean) => void;
	onChange: (f: T | null) => void;
	onSubmit: () => Promise<void>;
}) {
	const { formatMessage } = useIntl();
	const submitRef = useRef<HTMLElement>(null);
	const selectContainerRef = useRef<HTMLElement>(null);

	const dismissCreateMode = useCallback(async () => {
		setIsCreating(false);
		onChange(null);
		await tick();
		addFieldRef?.current?.focus();
	}, [addFieldRef, setIsCreating, onChange]);

	const requestCreateMode = useCallback(async () => {
		setIsCreating(true);
		await tick();
		selectContainerRef.current?.querySelector('input')?.focus();
	}, [setIsCreating]);

	const onChangeImpl = useCallback(
		async (field: T | null) => {
			onChange(field);
			await tick();
			submitRef.current?.focus();
		},
		[onChange],
	);

	const getOptionValue = useCallback((f: T) => f.fieldId, []);
	const getOptionLabel = useCallback((f: T) => f.name, []);

	if (!isCreating) {
		return (
			<AddFieldButton
				data-testId={dataTestIds.addFieldButton}
				ref={addFieldRef}
				onClick={requestCreateMode}
				shouldFitContainer
				appearance="subtle"
				// https://atlassian.design/components/icon/code#label
				iconBefore={<EditorAddIcon label="" />}
			>
				<AddFieldButtonText>{formatMessage(messages.buttonLabel)}</AddFieldButtonText>
			</AddFieldButton>
		);
	}

	return (
		<Grid
			xcss={createContainerStyles}
			alignItems="center"
			autoFlow="column"
			columnGap="space.100"
			templateColumns="auto 1fr auto auto"
		>
			<Box xcss={fakeDragButtonStyles}>
				<DragHandlerIcon
					// This drag button is not interactive and
					// it is entirely decorative
					label=""
					size="medium"
				/>
			</Box>
			<Grid
				ref={selectContainerRef}
				templateColumns="minmax(auto, 240px)"
				testId={dataTestIds.addFieldSelectContainer}
			>
				<Select
					aria-label={formatMessage(messages.selectFieldLabel)}
					placeholder={formatMessage(messages.selectFieldPlaceholder)}
					value={value}
					onChange={onChangeImpl}
					options={availableFields}
					getOptionLabel={getOptionLabel}
					getOptionValue={getOptionValue}
				/>
			</Grid>
			<Button appearance="subtle" onClick={dismissCreateMode}>
				{formatMessage(messages.cancel)}
			</Button>
			<LoadingButton
				ref={submitRef}
				testId={dataTestIds.addFieldSubmit}
				appearance="primary"
				isDisabled={!value}
				onClick={onSubmit}
				isLoading={isSubmitting}
			>
				{formatMessage(messages.save)}
			</LoadingButton>
		</Grid>
	);
};

const createContainerStyles = xcss({
	boxSizing: 'border-box',
	height: '56px',
	borderBottomStyle: 'solid',
	borderBottomColor: 'color.border',
	borderBottomWidth: '1px',
	paddingInline: 'space.100',
});

const fakeDragButtonStyles = xcss({
	color: 'color.text.disabled',
	cursor: 'not-allowed',
	background: 'color.background.disabled',
	borderRadius: 'border.radius.050',
	height: 'space.400',
	width: 'space.400',
	marginRight: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AddFieldButton = styled(Button)({
	// need to use this since @atlaskit/button still doesn't support style overrides via xcss
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		height: '45px',
		alignItems: 'center',
		paddingTop: 0,
		paddingRight: token('space.075', '6px'),
		paddingBottom: 0,
		paddingLeft: token('space.075', '6px'),
		marginBottom: '11px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > span': {
		marginLeft: token('space.025', '2px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AddFieldButtonText = styled.span({
	textAlign: 'left',
	width: '100%',
	paddingLeft: token('space.150', '12px'),
	display: 'inline-block',
});
