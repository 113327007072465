import React from 'react';
import { QuickFiltersSettingsPage } from '@atlassian/jira-software-board-settings-quick-filters/src/async.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';

const LazyQuickFiltersSettingsSpaOld = () => <QuickFiltersSettingsPage />;

const LazyQuickFiltersSettingsSpaNew = () => (
	<ContextualAnalyticsData sourceName="boardSettingsQuickFilters" sourceType={SCREEN}>
		<FireScreenAnalytics />
		<QuickFiltersSettingsPage />
	</ContextualAnalyticsData>
);

export const LazyQuickFiltersSettingsSpa = componentWithFG(
	'jsm_views_configuration_migration',
	LazyQuickFiltersSettingsSpaNew,
	LazyQuickFiltersSettingsSpaOld,
);

export default LazyQuickFiltersSettingsSpa;
