import React, { Component } from 'react';
import { v4 as uuid } from 'uuid';
import Picker from '@atlassian/jira-common-components-picker/src/base/index.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { Role, RoleOption, Props } from '../model/index.tsx';
import { requestRoles, requestRole } from '../services/index.tsx';
import messages from './messages.tsx';

type RoleOptions = RoleOption[];

export type State = {
	selectedRole: RoleOption | undefined;
	prevSelectedRole: RoleOption | undefined;
	options: RoleOptions;
};

const filterOptions = (filter = '') => {
	const lowerCaseFilter = filter.toLowerCase();
	// @ts-expect-error - TS7006 - Parameter 'option' implicitly has an 'any' type.
	return (option) => option.label && option.label.toLowerCase().includes(lowerCaseFilter);
};

const transformRoleIntoOption = (role?: Role): RoleOption | undefined =>
	role && {
		label: role.name,
		value: role.id,
		url: role.url,
		description: role.description,
	};

const transformOptionIntoRole = (option?: RoleOption): Role | undefined =>
	option && {
		name: option.label,
		id: option.value,
		url: option.url,
		description: option.description,
	};

// eslint-disable-next-line jira/react/no-class-components
export class RolePicker extends Component<Props, State> {
	static defaultProps = {
		isRequired: false,
		isDisabled: false,
		isClearable: true,
		isCompact: false,
		isCurrentMember: true,
		width: 200,
	};

	constructor(props: Props) {
		super(props);
		this.rolePickerId = `project-picker.label.id-${uuid()}`;
	}

	static getDerivedStateFromProps(props: Props, state: State) {
		const { selectedRole } = props;
		const currentRole = selectedRole && selectedRole.id;
		const prevRole = state.prevSelectedRole && state.prevSelectedRole.value;

		if (currentRole !== prevRole) {
			const selectedRoleOption = transformRoleIntoOption(selectedRole);
			return {
				...state,
				selectedRole: selectedRoleOption,
				prevSelectedRole: selectedRoleOption,
			};
		}

		return null;
	}

	state = {
		selectedRole: transformRoleIntoOption(this.props.selectedRole),
		prevSelectedRole: transformRoleIntoOption(this.props.selectedRole),
		options: [],
	};

	componentDidMount() {
		const { selectedRole } = this.state;

		if (selectedRole && !selectedRole.label) {
			this.fetchRole(selectedRole);
		}
	}

	componentDidUpdate() {
		const { selectedRole } = this.state;

		if (selectedRole && !selectedRole.label) {
			this.fetchRole(selectedRole);
		}
	}

	rolePickerId;

	onSelected = (selectedOption?: RoleOption) => {
		this.setState({ selectedRole: selectedOption });
		this.props.onChange(transformOptionIntoRole(selectedOption));
	};

	/* eslint-disable jira/react-arrow-function-property-naming */
	fetchOptions = (filter: string) => {
		const { baseUrl, projectId, isCurrentMember } = this.props;
		const { options } = this.state;

		return new Promise<RoleOptions>(
			(
				resolve: (result: Promise<RoleOptions> | RoleOptions) => void,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				reject: (error?: any) => void,
			) => {
				if (options.length) {
					return resolve(options.filter(filterOptions(filter)));
				}

				return requestRoles(baseUrl, projectId, isCurrentMember).then((newOptions) => {
					this.setState({ options: newOptions });
					return resolve(newOptions.filter(filterOptions(filter)));
				}, reject);
			},
		);
	};

	fetchRole(selectedRole: RoleOption) {
		const { baseUrl, projectId } = this.props;
		const optionValue = selectedRole.value;
		const updateSelectedRole = (
			newRole:
				| undefined
				| {
						readonly description?: string;
						readonly label?: string;
						readonly url?: string;
						readonly value: string;
				  },
		) => {
			const currentRole = this.state.selectedRole;
			const currentRoleValue = currentRole && currentRole.value;

			// Update the state only in case the project wasn't changed
			if (currentRoleValue === optionValue) {
				this.setState({ selectedRole: newRole });
			}
		};

		return requestRole(baseUrl, projectId, optionValue)
			.then((role) => updateSelectedRole(role))
			.catch(() => updateSelectedRole(undefined));
	}

	render() {
		const {
			intl: { formatMessage },
			baseUrl,
			onChange,
			projectId,
			selectedRole,
			...props
		} = this.props;

		const { selectedRole: role } = this.state;

		return (
			/* @ts-expect-error - Type '{ inputId: string; errorMessage: string; placeholder: string; onSelected: (selectedOption?: RoleOption | undefined) => void; options: (filter: string) => Promise<RoleOptions>; ... 8 more ...; "aria-label": string | undefined; }' is not assignable to type 'InexactPartial<Pick<Omit<ViewProps<RoleOption>, "intl">, "isRequired" | "isDisabled" | "isClearable" | "isCompact" | "label" | "width" | "isCreatable" | "isCheckbox" | ... 4 more ... | "noPortal">>'. */
			<Picker
				aria-label={!this.props.label ? formatMessage(messages.labelText) : undefined}
				{...props}
				inputId={!this.props.inputId ? this.rolePickerId : this.props.inputId}
				errorMessage={formatMessage(messages.error)}
				placeholder={formatMessage(messages.placeholder)}
				onSelected={this.onSelected}
				options={this.fetchOptions}
				value={role && role.label ? role : null}
			/>
		);
	}
}

export default injectIntl(RolePicker);
