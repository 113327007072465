import React, { useCallback, useMemo } from 'react';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import type {
	FieldMode,
	CurrentField,
} from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-layout.tsx';
import { SuccessFlagWithBoardLink } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/success-flag-with-board-link/index.tsx';
import { PLAN_MODE } from '../../../common/constants/card-layout.tsx';
import { useDeleteCardLayoutField } from '../../../services/delete-card-layout-field/index.tsx';
import { useIsScrumBoard, useRapidViewId } from '../../selectors/board/index.tsx';
import {
	useCardLayoutStoreActions,
	useAvailableFields,
} from '../../selectors/card-layout/index.tsx';
import { useFetchCardLayoutConfigHandler } from '../fetch-card-layout-config-handler/index.tsx';
import messages from './messages.tsx';

export const useDeleteCardLayoutFieldHandler = (fieldMode: FieldMode) => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();

	const [availableFields] = useAvailableFields();
	const [rapidViewId] = useRapidViewId();
	const [isScrumBoard] = useIsScrumBoard();
	const [, { deleteCurrentField }] = useCardLayoutStoreActions();

	const { handleFetchCardLayoutConfig } = useFetchCardLayoutConfigHandler();
	const { commit: commitDeleteCardLayoutField, loading: isDeleteSubmitting } =
		useDeleteCardLayoutField(rapidViewId, fieldMode);

	const fieldLocation = useMemo(() => {
		if (fieldMode === PLAN_MODE) {
			return messages.deleteSuccessBacklogFlagTitle;
		}
		return isScrumBoard
			? messages.deleteSuccessActiveSprintsFlagTitle
			: messages.deleteSuccessKanbanBoardFlagTitle;
	}, [fieldMode, isScrumBoard]);

	const shouldReloadFieldsAfterDelete = useMemo(
		() => !availableFields.some((it) => it.mode === fieldMode),
		[availableFields, fieldMode],
	);

	const commitFieldDelete = useCallback(
		async (field: CurrentField) => {
			const result = await commitDeleteCardLayoutField(field);

			if (result.ok) {
				deleteCurrentField(field);
				showFlag((props) => (
					<SuccessFlagWithBoardLink
						{...props}
						title={formatMessage(fieldLocation, {
							fieldName: field.name,
						})}
						isCMPBoard
					/>
				));
			}

			if (shouldReloadFieldsAfterDelete) {
				await handleFetchCardLayoutConfig();
			}
		},
		[
			shouldReloadFieldsAfterDelete,
			commitDeleteCardLayoutField,
			handleFetchCardLayoutConfig,
			deleteCurrentField,
			formatMessage,
			fieldLocation,
			showFlag,
		],
	);

	return {
		commitFieldDelete,
		isDeleteSubmitting,
	};
};
