// eslint-disable-next-line jira/restricted/react-component-props
import React, { Component, type ComponentProps } from 'react';
import { v4 as uuid } from 'uuid';
import { PickerWithAvatar } from '@atlassian/jira-common-components-picker/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { Project, Props, State, PickedProject } from '../model/index.tsx';
import { requestProjects, requestProject } from '../services/index.tsx';
import messages from './messages.tsx';

// eslint-disable-next-line jira/react/no-class-components
export class ProjectPicker extends Component<Props, State> {
	static defaultProps = {
		isRequired: false,
		isDisabled: false,
		isClearable: true,
		isCompact: false,
		detailed: false,
		width: '200px',
		requireBrowsePermission: false,
		label: undefined,
		inputId: undefined,
		projectType: undefined,
	};

	constructor(props: Props) {
		super(props);
		this.projectPickerId = `project-picker.label.id-${uuid()}`;
	}

	static getDerivedStateFromProps(props: Props, state: State) {
		const currentProjectId = props.selectedProject && props.selectedProject.value;
		const prevProjectId = state.prevSelectedProject && state.prevSelectedProject.value;

		if (currentProjectId !== prevProjectId) {
			return {
				...state,
				selectedProject: props.selectedProject,
				prevSelectedProject: props.selectedProject,
			};
		}

		return null;
	}

	state = {
		selectedProject: this.props.selectedProject,
		projects: [],
		prevSelectedProject: this.props.selectedProject,
	};

	componentDidMount() {
		const { selectedProject } = this.state;

		if (selectedProject && !selectedProject.name) {
			this.fetchProject(selectedProject);
		}
	}

	componentDidUpdate() {
		const { selectedProject } = this.state;

		if (selectedProject && !selectedProject.name) {
			this.fetchProject(selectedProject);
		}
	}

	projectPickerId;

	onSelected = (selectedOption?: PickedProject) => {
		this.setState({ selectedProject: selectedOption });
		this.props.onChange(selectedOption);
	};

	/* eslint-disable jira/react-arrow-function-property-naming */
	formatOptionDataTransformer = (project: Project) => {
		const { avatarUrl, name } = project;
		const avatar =
			this.props.renderAvatar &&
			expVal('jpd_create_idea_template_experiment', 'ideaCreateFormExperimentEnabled', false)
				? this.props.renderAvatar(avatarUrl)
				: avatarUrl;
		return {
			avatar,
			label: name,
		};
	};

	/* eslint-disable jira/react-arrow-function-property-naming */
	fetchOptions = async (filter: string) => {
		const projects = await requestProjects(this.props.baseUrl, filter, {
			detailed: this.props.detailed,
			requireBrowsePermission: this.props.requireBrowsePermission,
			projectType: this.props.projectType,
		});

		this.setState({ projects });

		return projects;
	};

	fetchProject(selectedProject: Project) {
		const { baseUrl } = this.props;
		const projectValue = selectedProject.value;
		// @ts-expect-error - TS7006 - Parameter 'newProject' implicitly has an 'any' type.
		const updateSelectedProject = (newProject) => {
			const currentProject = this.state.selectedProject;
			const currentProjectValue = currentProject && currentProject.value;

			// Update the state only in case the project wasn't changed
			if (currentProjectValue === projectValue) {
				this.setState({ selectedProject: newProject });
				this.props.onChange(newProject);
			}
		};

		return requestProject(baseUrl, projectValue)
			.then((project) => updateSelectedProject(project))
			.catch(() => updateSelectedProject(undefined));
	}

	render() {
		const {
			intl: { formatMessage },
			baseUrl,
			selectedProject,
			onChange,
			...props
		} = this.props;

		const { selectedProject: project, projects } = this.state;

		// the selected option needs to have same object reference to be correctly announced by screen readers
		const selectedOptionWithObjectReference = projects?.find(
			(projectInList: Project) => projectInList.value === project?.value,
		);
		const selectedOption =
			selectedOptionWithObjectReference || (project && project.name ? project : null);

		return (
			// @ts-expect-error - TS2769 - No overload matches this call.
			<PickerWithAvatar
				aria-label={!this.props.label ? formatMessage(messages.labelText) : undefined}
				{...props}
				inputId={!this.props.inputId ? this.projectPickerId : this.props.inputId}
				errorMessage={formatMessage(messages.error)}
				placeholder={this.props.placeholder || formatMessage(messages.placeholder)}
				onSelected={this.onSelected}
				options={this.fetchOptions}
				formatOptionDataTransformer={this.formatOptionDataTransformer}
				value={selectedOption}
				showFocusedAvatar
			/>
		);
	}
}

export default injectIntl(ProjectPicker);

export type ProjectPickerProps = JSX.LibraryManagedAttributes<
	typeof ProjectPicker,
	ComponentProps<typeof ProjectPicker>
>;
