export const WORK = 'work' as const;
export const PLAN = 'plan' as const;
export const INITIAL_DATA = 'initial-data' as const;
export const CONFIG_DATA = 'config-data' as const;
export const REPORT = 'report' as const;

export const SUCCESS = 'success';
export const FAILURE = 'failure';
export const ALL_DATA_PROMISE = 'all-data-promise';

export const GH_SPA_RESOURCES_READY = 'GH_SPA_RESOURCES_READY';
export const GH_SPA_RB_READY = 'GH_SPA_RB_READY';
export const GH_SPA_CONTENT_READY = 'GH_SPA_SPINNER_HIDE';
export const GH_SPA_CONTENT_LOADING = 'GH_SPA_SPINNER_SHOW';

export const BENTO_ROOT = 'ghx-issue-fragment';
