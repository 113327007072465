import { useMemo } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { SETTINGS_CONFIG } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import useServiceProjectFeatures from '@atlassian/jira-servicedesk-features-store/src/index.tsx';
import { ENABLED } from '@atlassian/jira-servicedesk-common/src/service-project-features/constants.tsx';
import { JSM_MENU_ITEMS, JSW_MENU_ITEMS } from './constants.tsx';
import type { SettingsLink } from './types.tsx';

export const useJswMenuItems = ({
	isUserLocatedBoard,
	isKanban,
}: {
	isUserLocatedBoard: boolean;
	isKanban: boolean;
}): SettingsLink[] => {
	const { formatMessage } = useIntl();

	return useMemo(
		() =>
			JSW_MENU_ITEMS.flatMap((item) => {
				return (SETTINGS_CONFIG[item.id].userLocatedBoardAllowed || !isUserLocatedBoard) &&
					(SETTINGS_CONFIG[item.id].kanbanAllowed || !isKanban)
					? [
							{
								...item,
								name: formatMessage(item.name),
								configQuery: SETTINGS_CONFIG[item.id].configQuery,
							},
						]
					: [];
			}),
		[formatMessage, isUserLocatedBoard, isKanban],
	);
};

export const useJsmMenuItems = (): SettingsLink[] => {
	const { formatMessage } = useIntl();

	const { data } = useServiceProjectFeatures();
	const isCalendarEnabled = data ? data['jsm-calendar']?.status === ENABLED : false;
	const isBoardEnabled = data ? data['jsm-board']?.status === ENABLED : false;

	return useMemo(
		() =>
			JSM_MENU_ITEMS.flatMap((item) => {
				return SETTINGS_CONFIG[item.id].isJsmFeatureVisible({
					isCalendarEnabled,
					isBoardEnabled,
				})
					? [
							{
								...item,
								name: formatMessage(item.name),
								configQuery: SETTINGS_CONFIG[item.id].configQuery,
							},
						]
					: [];
			}),
		[formatMessage, isCalendarEnabled, isBoardEnabled],
	);
};
