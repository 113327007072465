import React from 'react';
import { EstimationSettingsPage } from '@atlassian/jira-software-board-settings-estimation/src/async.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';

const LazyEstimationSettingsSpaOld = () => <EstimationSettingsPage />;

const LazyEstimationSettingsSpaNew = () => (
	<ContextualAnalyticsData sourceName="boardSettingsEstimation" sourceType={SCREEN}>
		<FireScreenAnalytics />
		<EstimationSettingsPage />
	</ContextualAnalyticsData>
);

const LazyEstimationSettingsSpa = componentWithFG(
	'jsm_views_configuration_migration',
	LazyEstimationSettingsSpaNew,
	LazyEstimationSettingsSpaOld,
);

export default LazyEstimationSettingsSpa;
