import React, { useMemo } from 'react';
import { LinkButton } from '@atlaskit/button/new';
import ArrowLeftIcon from '@atlaskit/icon/glyph/arrow-left';
import { Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import BreadcrumbRenderer from '@atlassian/jira-breadcrumbs/src/breadcrumb-renderer/index.tsx';
import PageHeader from '@atlassian/jira-common-components-page-header/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useBoardAndLocationContext } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/controllers/use-board-and-location-context/index.tsx';
import { useSettingsContainerContext } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/controllers/use-settings-container-context/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import { useBoardUrl } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/controllers/use-board-url/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { SettingsContainerBreadcrumbs } from './breadcrumbs/index.tsx';
import messages from './messages.tsx';

export function HeaderOld() {
	const { formatMessage } = useIntl();
	const [{ location }] = useRouter();
	const { board } = useBoardAndLocationContext();
	const { boardName } = useSettingsContainerContext();
	const boardUrl = useBoardUrl();
	const backToBoardUrl = useMemo(() => {
		const backlogPath = location.search.includes('from-backlog=1') ? '/backlog' : '';
		return getWillShowNav4() && fg('blu-6483-fe-add-board-settings-route')
			? boardUrl + backlogPath
			: location.pathname;
	}, [boardUrl, location]);

	const breadcrumbs = (
		<>
			<BreadcrumbRenderer />
			<SettingsContainerBreadcrumbs />
		</>
	);

	return (
		<PageHeader
			breadcrumbs={breadcrumbs}
			actions={
				<LinkButton href={backToBoardUrl} appearance="subtle">
					<Inline xcss={buttonTextStyles} alignBlock="center" as="span">
						<ArrowLeftIcon size="small" label="" />
						{formatMessage(messages.backToBoard)}
					</Inline>
				</LinkButton>
			}
		>
			{formatMessage(messages.boardSettingsTitle, { boardName: boardName ?? board?.name ?? '' })}
		</PageHeader>
	);
}

const buttonTextStyles = xcss({
	color: 'color.text.subtle',
	fontWeight: token('font.weight.regular'),
});
