import React, { useCallback, useState } from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { SuccessFlagWithTimelineLink } from '../../../common/ui/success-flag-with-timeline-link/index.tsx';
import { useUpdateChildPlanning } from '../../../services/update-child-planning/index.tsx';
import { useIsCMPBoard, useRapidViewId } from '../../selectors/board/index.tsx';
import { useIsChildIssuePlanningEnabled } from '../../selectors/timeline/index.tsx';
import messages from './messages.tsx';

// Using aliases for testing purposes
export const useEnableChildIssuePlanning = (rapidViewId: number) =>
	useUpdateChildPlanning(rapidViewId, true);
export const useDisableChildIssuePlanning = (rapidViewId: number) =>
	useUpdateChildPlanning(rapidViewId, false);

export const useUpdateChildPlanningHandler = () => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();

	const [rapidViewId] = useRapidViewId();
	const [isCMPBoard] = useIsCMPBoard();
	const [isChildIssuePlanningEnabled, { updateIsChildIssuePlanningEnabled }] =
		useIsChildIssuePlanningEnabled();

	const { commit: commitEnableChildPlanning } = useEnableChildIssuePlanning(rapidViewId);
	const { commit: commitDisableChildPlanning } = useDisableChildIssuePlanning(rapidViewId);

	const [isEnabled, setIsEnabled] = useState(isChildIssuePlanningEnabled);

	const updateChildPlanning = useCallback(async () => {
		const nextValue = !isEnabled;
		setIsEnabled(nextValue);

		const commit = nextValue ? commitEnableChildPlanning : commitDisableChildPlanning;
		const result = await commit();

		if (!result.ok) {
			setIsEnabled(isEnabled);
			return;
		}

		const enableChildLevelIssuePlanningSuccessMessage = expVal(
			'issue-terminology-refresh-m2-replace',
			'isEnabled',
			false,
		)
			? messages.enableChildLevelIssuePlanningSuccessIssueTermRefresh
			: messages.enableChildLevelIssuePlanningSuccess;

		const disableChildLevelIssuePlanningSuccessMessage = expVal(
			'issue-terminology-refresh-m2-replace',
			'isEnabled',
			false,
		)
			? messages.disableChildLevelIssuePlanningSuccessIssueTermRefresh
			: messages.disableChildLevelIssuePlanningSuccess;

		updateIsChildIssuePlanningEnabled(nextValue);
		showFlag((props) => (
			<SuccessFlagWithTimelineLink
				{...props}
				title={formatMessage(
					nextValue
						? enableChildLevelIssuePlanningSuccessMessage
						: disableChildLevelIssuePlanningSuccessMessage,
				)}
				isCMPBoard={isCMPBoard}
			/>
		));
	}, [
		commitDisableChildPlanning,
		commitEnableChildPlanning,
		formatMessage,
		isCMPBoard,
		isEnabled,
		showFlag,
		updateIsChildIssuePlanningEnabled,
	]);

	return { isEnabled, updateChildPlanning };
};
