import { useJiraUserBoardNavigationResource } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/jsw-user-board-navigation-resource/index.tsx';
import { useJiraServiceManagementProjectNavigation } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/project-sidebar-navigation-resource/index.tsx';
import type { BoardInfo } from '@atlassian/jira-navigation-apps-resources/src/services/sidebar/jsw-userboard-sidebar-navigation/types.tsx';
import { useIsUserLocatedBoard } from '../use-is-user-located-board/index.tsx';

export const useJsmBoardAndLocationContext = () => {
	const isUserLocated = useIsUserLocatedBoard();

	const { data: projectData, error: projectError } = useJiraServiceManagementProjectNavigation();
	// This hook returns user-located board data
	const { data: userData, error: userError } = useJiraUserBoardNavigationResource();

	const board: BoardInfo = {
		id: (isUserLocated ? userData?.boardInfo.id : projectData?.boardInfo?.board.id) ?? 0,
		name: (isUserLocated ? userData?.boardInfo.name : projectData?.boardInfo?.board.name) ?? '',
		type: isUserLocated ? userData?.boardInfo.type : projectData?.boardInfo?.board.type,
	};

	return {
		project: projectData?.project,
		user: userData?.owner,
		board,
		error: isUserLocated ? userError : projectError,
	};
};
