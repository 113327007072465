import React from 'react';
import { CardLayoutSettingsPage } from '@atlassian/jira-software-board-settings-card-layout/src/async.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';

const LazyCardLayoutSettingsSpaOld = () => <CardLayoutSettingsPage />;

const LazyCardLayoutSettingsSpaNew = () => (
	<ContextualAnalyticsData sourceName="boardSettingsCardLayout" sourceType={SCREEN}>
		<FireScreenAnalytics />
		<CardLayoutSettingsPage />
	</ContextualAnalyticsData>
);

const LazyCardLayoutSettingsSpa = componentWithFG(
	'jsm_views_configuration_migration',
	LazyCardLayoutSettingsSpaNew,
	LazyCardLayoutSettingsSpaOld,
);

export default LazyCardLayoutSettingsSpa;
