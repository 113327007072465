import { useMemo } from 'react';
import { useQueryParam } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { useSelectedConfigTab } from '../../controllers/use-selected-config-tab/index.tsx';
import {
	getIsProjectOnly,
	getIsScrumOnly,
	getIsJswOnly,
	isValidConfigParam,
	getIsJsmOnly,
} from './utils.tsx';

export const LastOpenedConfigTab = ({
	configParam,
	isUserLocatedBoard,
	isKanban,
	isJsm,
}: {
	configParam: string;
	isUserLocatedBoard: boolean;
	isKanban: boolean;
	isJsm: boolean;
}) => {
	const [, setConfigParam] = useQueryParam('config');
	const { getSelectedConfigTab, setSelectedConfigTab } = useSelectedConfigTab();

	const defaultTab = 'filter';
	const selectedTab = getSelectedConfigTab();
	const lastOpenedTab = selectedTab ? JSON.parse(selectedTab) : defaultTab;

	const shouldOverride = useMemo(() => {
		if (!isValidConfigParam(configParam)) {
			return false;
		}

		return (
			// override (send to default) if current board is user located and config param and last opened config tab is project board only
			(isUserLocatedBoard && getIsProjectOnly(configParam) && getIsProjectOnly(lastOpenedTab)) ||
			// override (send to default) if current board is kanban and config param and last opened config tab is scrum only
			(isKanban && getIsScrumOnly(configParam) && getIsScrumOnly(lastOpenedTab)) ||
			// override (send to default) if current board is JSM and config param and last opened config tab is JSW only
			// eslint-disable-next-line jira/ff/no-preconditioning
			(fg('jsm_views_configuration_migration') && // avoid executing functions when fg is disabled
				isJsm &&
				getIsJswOnly(configParam) &&
				getIsJswOnly(lastOpenedTab)) ||
			// override (send to default) if current board is JSW and config param and last opened config tab is JSM only
			// eslint-disable-next-line jira/ff/no-preconditioning
			(fg('jsm_views_configuration_migration') && // avoid executing functions when fg is disabled
				!isJsm &&
				getIsJsmOnly(configParam) &&
				getIsJsmOnly(lastOpenedTab))
		);
	}, [configParam, lastOpenedTab, isJsm, isKanban, isUserLocatedBoard]);

	if (shouldOverride) {
		setConfigParam(defaultTab);
		setSelectedConfigTab(defaultTab);
	} else {
		setConfigParam(lastOpenedTab);
	}

	return null;
};
