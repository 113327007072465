import React, { useState, useCallback } from 'react';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { SuccessFlagWithBoardLink } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/success-flag-with-board-link/index.tsx';
import {
	useIsCMPBoard,
	useRapidViewId,
} from '../../../../../controllers/selectors/board/index.tsx';
import { useDefaultSwimlane } from '../../../../../controllers/selectors/swimlanes/index.tsx';
import { useUpdateSwimlane } from '../../../../../services/update-swimlane/index.tsx';
import messages from './messages.tsx';

export const useUpdateDefaultSwimlane = () => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();
	const [isCMPBoard] = useIsCMPBoard();
	const [rapidViewId] = useRapidViewId();
	const [swimlane, { updateDefaultSwimlaneName }] = useDefaultSwimlane();

	const { commit: commitUpdateSwimlane } = useUpdateSwimlane(
		rapidViewId,
		formatMessage(messages.failedToUpdateErrorTitle),
	);

	const [nextDefaultSwimlaneName, setNextDefaultSwimlaneName] = useState<string | null>(null);

	const onUpdate = useCallback(
		async (update: string) => {
			setNextDefaultSwimlaneName(update);
			if (!swimlane || swimlane.name === update) return;

			const result = await commitUpdateSwimlane({
				...swimlane,
				id: String(swimlane.id),
				name: update,
			});

			if (result && !result.errors) {
				updateDefaultSwimlaneName(update);
				showFlag((props) => (
					<SuccessFlagWithBoardLink
						isCMPBoard={isCMPBoard}
						title={formatMessage(messages.successfullyUpdateDefaultSwimlane)}
						{...props}
					/>
				));
			}

			setNextDefaultSwimlaneName(null);
		},
		[
			commitUpdateSwimlane,
			formatMessage,
			isCMPBoard,
			swimlane,
			showFlag,
			updateDefaultSwimlaneName,
		],
	);

	return {
		name: nextDefaultSwimlaneName ?? swimlane?.name ?? '',
		onUpdate,
	};
};
