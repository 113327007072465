import type { DateTimeFormatOptionsV2 as DateTimeFormatOptions } from '@atlassian/jira-intl/src/v2/types.tsx';

const WORKING_DAYS_API_PREFIX = '/rest/greenhopper/1.0/rapidviewconfig/workingdays';

const METRICS_PAGE_ID = 'board-settings-apps.working-days-settings-page';
export const BOARD_SETTINGS_ARTICLE_ID_WORKING_DAYS = '4ihwicvjvrBEF78amZzHYZ';
export const BOARD_SETTINGS_ARTICLE_ID_WORKING_DAYS_ANALYTICS_ID = `${METRICS_PAGE_ID}.cmp.article.analytics`;

export const BOARD_SETTINGS_WORKING_DAYS_PACKAGE = 'board-settings.working-days';

export const UPDATE_TIME_ZONE_API_URL = `${WORKING_DAYS_API_PREFIX}/timezone`;
export const UPDATE_TIME_ZONE_ID_ACTION_SUBJECT = 'updateTimeZoneIdForWorkingDays';
export const UPDATE_TIME_ZONE_ID_BROWSER_METRICS_KEY = 'update-timezone-id-for-working-days';

export const UPDATE_WEEK_DAYS_API_URL = `${WORKING_DAYS_API_PREFIX}/weekdays`;
export const UPDATE_WEEK_DAYS_ACTION_SUBJECT = 'updateWeekdaysForWorkingDays';
export const UPDATE_WEEK_DAYS_BROWSER_METRICS_KEY = 'update-weekdays-for-working-days';

export const ADD_NON_WORKING_DATE_API_URL = `${WORKING_DAYS_API_PREFIX}/nonworkingday/add`;
export const ADD_NON_WORKING_DATE_ACTION_SUBJECT = 'addNonWorkingDate';
export const ADD_NON_WORKING_DATE_BROWSER_METRICS_KEY = 'add-non-working-date';

export const REMOVE_NON_WORKING_DATE_API_URL = `${WORKING_DAYS_API_PREFIX}/nonworkingday/remove`;
export const REMOVE_NON_WORKING_DATE_ACTION_SUBJECT = 'removeNonWorkingDate';
export const REMOVE_NON_WORKING_DATE_BROWSER_METRICS_KEY = 'remove-non-working-date';

export const ADDED_DATE_ANNOUNCER_TEST_ID =
	'software-board-settings-working-days.ui.working-days-settings-page.non-working-dates-settings.added-date';
export const REMOVED_DATE_ANNOUNCER_TEST_ID =
	'software-board-settings-working-days.ui.working-days-settings-page.non-working-dates-settings.removed-date';
export const SELECTED_DATE_ANNOUNCER_TEST_ID =
	'software-board-settings-working-days.ui.working-days-settings-page.non-working-dates-settings.selected-date';
export const SORTED_DATE_ANNOUNCER_TEST_ID =
	'software-board-settings-working-days.ui.working-days-settings-page.non-working-dates-settings.sorted-date';
export const ADD_DATE_BUTTON_TEST_ID =
	'software-board-settings-working-days.ui.working-days-settings-page.non-working-dates-settings.add-button';

export const NUMBER_OF_DATES_PER_PAGE = 10;

// This is the date format option that make it easier for accessbility reader to read
export const A11Y_DATE_FORMAT_OPTION: DateTimeFormatOptions = {
	day: 'numeric',
	month: 'long',
	year: 'numeric',
};

export const DATE_INPUT_FORMAT_OPTION: DateTimeFormatOptions = {
	day: '2-digit',
	month: '2-digit',
	year: 'numeric',
};

export const COMPONENT_ID = 'rapidboard_settings_working_days';
export const APP_IDENTIFIER = 'board-settings-working-days';
export const APP_TEAM_NAME = 'a4t-pandora';
export const WORKING_DAYS_SLA_EXPERIENCE = 'viewBoardSettingsWorkingDays';
export const WORKING_DAYS_ANALYTICS_SOURCE = 'board-settings-working-days';

export const AUTO_FOCUS_DELAY_MS = 2000;

export const BOARD_SETTINGS_WORKING_DAYS_UFO_NAME = 'board-settings.working-days';
