import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { boardSettingsQueryConfigParamValues } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import messages from './messages.tsx';

type MenuId = keyof typeof boardSettingsQueryConfigParamValues;

type MenuItem = {
	id: MenuId;
	name: MessageDescriptor;
};

export const JSW_MENU_ITEMS: MenuItem[] = [
	{
		id: 'filter',
		name: messages.generalMenuItemLabel,
	},
	{
		id: 'columns',
		name: messages.columnsMenuItemLabel,
	},
	{
		id: 'swimlanes',
		name: messages.swimlanesMenuItemLabel,
	},
	{
		id: 'quickFilters',
		name: messages.quickFiltersMenuItemLabel,
	},
	{
		id: 'cardColors',
		name: messages.cardColoursMenuItemLabel,
	},
	{
		id: 'cardLayout',
		name: messages.cardLayoutMenuItemLabel,
	},
	{
		id: 'estimation',
		name: messages.estimationMenuItemLabel,
	},
	{
		id: 'time',
		name: messages.workingDaysMenuItemLabel,
	},
	{
		id: 'timelineConfig',
		name: messages.timelineMenuItemLabel,
	},
];

export const JSM_MENU_ITEMS: MenuItem[] = [
	{
		id: 'filter',
		name: messages.generalMenuItemLabel,
	},
	{
		id: 'columns',
		name: messages.columnsMenuItemLabel,
	},
	{
		id: 'swimlanes',
		name: messages.swimlanesMenuItemLabel,
	},
	{
		id: 'quickFilters',
		name: messages.quickFiltersMenuItemLabel,
	},
	{
		id: 'cardColors',
		name: messages.cardColoursMenuItemLabel,
	},
	{
		id: 'cardLayout',
		name: messages.cardLayoutMenuItemLabel,
	},
	{
		id: 'dateFields',
		name: messages.dateFieldsMenuItemLabel,
	},
];
