import React from 'react';
import { Stack, xcss } from '@atlaskit/primitives';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { CONTENT_WIDTH } from '../common/constants/index.tsx';
import { BoardFilterDetails } from './board-filter-details/index.tsx';
import { BoardFilterQuery } from './board-filter-query/index.tsx';
import { BoardFilterSection } from './board-filter-section/index.tsx';

export const BoardFilterSettingsFields = () => {
	return (
		<UFOSegment name="board-filter-settings-fields">
			<Stack xcss={containerStyles} space="space.200">
				<BoardFilterSection />
				<BoardFilterQuery />
				<BoardFilterDetails />
			</Stack>
		</UFOSegment>
	);
};

// the code is for JSM Calendar GA release
// and will be removed in https://hello.jira.atlassian.cloud/browse/CORCAP-1682 once we have a new tab for calendar setting
export const BoardFilterSettingsFieldsWithNoMargin = () => {
	return (
		<UFOSegment name="board-filter-settings-fields">
			<Stack xcss={containerMaxWidthStyles} space="space.200">
				<BoardFilterSection />
				<BoardFilterQuery />
				<BoardFilterDetails />
			</Stack>
		</UFOSegment>
	);
};

const containerStyles = xcss({
	marginTop: 'space.500',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${CONTENT_WIDTH}px`,
});

const containerMaxWidthStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${CONTENT_WIDTH}px`,
});
