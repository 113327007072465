import { fg } from '@atlassian/jira-feature-gating';
import parameters from '@atlassian/jira-relay/src/__generated__/quickFilters_RootQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = {
	boardId: string;
	isSimplifiedBoardsM2Enabled: boolean;
	isPerformanceImprovement: boolean;
};

export const quickFiltersSettingPageEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-jsw-board-settings-quickfilters" */ './ui/quick-filters-settings-page/index'
		).then((module) => module.QuickFiltersSettingsPageContent),
	),
	getPreloadProps: ({
		boardId,
		isSimplifiedBoardsM2Enabled,
		isPerformanceImprovement,
	}: EntryPointParams) => ({
		queries: {
			queryReference: {
				options: {
					fetchPolicy: 'network-only' as const,
					networkCacheConfig: fg('jsw_board_setting_use_agg_slow_proxy')
						? {
								metadata: { META_SLOW_ENDPOINT: true },
							}
						: undefined,
				},
				parameters,
				variables: {
					boardId,
					isCMP: true,
					isSimplifiedBoardsM2Enabled,
					isPerformanceImprovement,
				},
			},
		},
	}),
});
