import React, { Component, type KeyboardEvent, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import memoizeOne from 'memoize-one';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/standard-button';
import { HelperMessage } from '@atlaskit/form';
import { Flex } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import GroupPicker from '@atlassian/jira-group-picker/src/index.tsx';
import { FormattedMessage } from '@atlassian/jira-intl';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import ProjectPicker from '@atlassian/jira-project-picker/src/index.tsx';
import type { PickedProject, Project } from '@atlassian/jira-project-picker/src/model/index.tsx';
import RolePicker from '@atlassian/jira-role-picker/src/index.tsx';
import type { AccountId, Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	GROUP_SHARE,
	OPEN_SHARE,
	PROJECT_SHARE,
	PUBLIC_SHARE,
	type SharePermissionGroup,
	type SharePermissionProject,
	type SharePermissionProjectRole,
	type SharePermissionUser,
	USER_SHARE,
} from '@atlassian/jira-shared-types/src/share-permission.tsx';
import UserPicker from '@atlassian/jira-user-picker/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { PRIVATE_SHARE } from '../../../model/constants.tsx';
import type { Option, SharePermissionOrPrivate, ShareType } from '../../../model/types.tsx';
import messages from './messages.tsx';
import ShareeTypePicker, { EditorTypePicker } from './sharee-type-picker/index.tsx';

export type Props = {
	readonly isShareScopeLoading: boolean;
	readonly isEditorList: boolean;
	readonly ariaLabel?: string;
	readonly ariaLabelledby?: string;
} & Intl & {
		readonly options: Option[];
		readonly type: ShareType | undefined;
		readonly accountId: AccountId;
		readonly isAdmin: boolean;
		readonly onAddSharePermission: (arg1: SharePermissionOrPrivate, arg2: UIAnalyticsEvent) => void;
	};

type State = {
	readonly selectedType: ShareType;
	readonly previousPropsType?: ShareType;
	readonly selectedGroup?: SharePermissionGroup;
	readonly selectedProject?: SharePermissionProject;
	readonly selectedRole?: SharePermissionProjectRole;
	readonly selectedUser?: SharePermissionUser;
};

const mapToPickerProject = memoizeOne((project?: SharePermissionProject): Project | undefined => {
	if (!project) return undefined;

	return {
		value: project.id,
		name: project.name,
		avatarUrl: project.avatarUrl,
	};
});

const mapFromPickerProject = memoizeOne(
	(project: PickedProject): SharePermissionProject => ({
		id: project.value,
		name: project.name,
		avatarUrl: project.avatarUrl,
	}),
);

const defaultPickerType: ShareType = GROUP_SHARE;

const getStateChangesOnTypeChange = (type: ShareType): Partial<State> => ({
	selectedType: type,
	selectedGroup: undefined,
	selectedProject: undefined,
	selectedRole: undefined,
	selectedUser: undefined,
});

// eslint-disable-next-line jira/react/no-class-components
export class ShareePicker extends Component<Props, State> {
	static defaultProps = {
		type: undefined,
	};

	static getDerivedStateFromProps(props: Props, state: State) {
		const { type } = props;
		const { selectedType, previousPropsType } = state;

		if (!type || type === previousPropsType || type === selectedType) {
			return {
				previousPropsType: type,
			};
		}

		return {
			previousPropsType: type,
			...getStateChangesOnTypeChange(type || selectedType || defaultPickerType), // type can be undefined e.g. when user clicked Add to add a new row. If it is not passed via props - preserve currently selected dropdown value
		};
	}

	state = {
		// disabling unused state checks due to https://github.com/yannickcr/eslint-plugin-react/issues/1759
		previousPropsType: undefined,
		selectedType: defaultPickerType,
		selectedGroup: undefined,
		selectedProject: undefined,
		selectedRole: undefined,
		selectedUser: undefined,
	};

	onTypeChange = (type: ShareType) => {
		// @ts-expect-error - TS2345 - Argument of type 'Partial<State>' is not assignable to parameter of type 'State | ((prevState: Readonly<State>, props: Readonly<Props>) => State | Pick<State, keyof State> | null) | Pick<...> | null'.
		this.setState(getStateChangesOnTypeChange(type));
	};

	onUserChange = (value: SharePermissionUser) => {
		this.setState({
			selectedUser: value,
		});
	};

	onGroupChange = (value: SharePermissionGroup) => {
		this.setState({
			selectedGroup: value,
		});
	};

	onProjectChange = (value?: PickedProject) => {
		this.setState({
			selectedProject: value && mapFromPickerProject(value),
			selectedRole: undefined,
		});
	};

	onRoleChange = (role?: SharePermissionProjectRole) => {
		this.setState({
			selectedRole: role,
		});
	};

	onAdd = (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		const { onAddSharePermission } = this.props;
		const { selectedType, selectedGroup, selectedProject, selectedRole, selectedUser } = this.state;

		switch (selectedType) {
			case GROUP_SHARE: {
				if (selectedGroup) {
					onAddSharePermission(
						{
							type: GROUP_SHARE,
							group: selectedGroup,
						},
						analyticsEvent,
					);
				}
				break;
			}
			case USER_SHARE: {
				if (selectedUser) {
					onAddSharePermission(
						{
							type: USER_SHARE,
							user: selectedUser,
						},
						analyticsEvent,
					);
				}
				break;
			}
			case PROJECT_SHARE: {
				if (selectedProject) {
					onAddSharePermission(
						{
							type: PROJECT_SHARE,
							project: selectedProject,
							role: selectedRole,
						},
						analyticsEvent,
					);
				}
				break;
			}
			case PRIVATE_SHARE: {
				onAddSharePermission(
					{
						type: PRIVATE_SHARE,
					},
					analyticsEvent,
				);
				break;
			}
			case PUBLIC_SHARE: {
				onAddSharePermission(
					{
						type: PUBLIC_SHARE,
					},
					analyticsEvent,
				);
				break;
			}
			case OPEN_SHARE: {
				onAddSharePermission(
					{
						type: OPEN_SHARE,
					},
					analyticsEvent,
				);
				break;
			}
			default:
				return undefined;
		}

		return undefined;
	};

	getShareTypeHelperMessage() {
		const { selectedType } = this.state;
		if (this.props.isShareScopeLoading) {
			return messages.shareScopeLoadingHelperMessage;
		}
		switch (selectedType) {
			case PRIVATE_SHARE: {
				return messages.privateShareHelperMessage;
			}
			case PUBLIC_SHARE: {
				return messages.safePublicShareHelperMessage;
			}
			case OPEN_SHARE: {
				return messages.openShareHelperMessage;
			}
			default:
				return undefined;
		}
	}

	getOptionsWithHelperMessage() {
		const {
			options,
			intl: { formatMessage },
		} = this.props;

		const { selectedType } = this.state;
		const message = this.getShareTypeHelperMessage();
		return options.map((option) => {
			if (message && option.value === selectedType) {
				return {
					...option,

					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					label: formatMessage(message, { website: window.location.host }),
				};
			}
			return option;
		});
	}

	onKeyDown(event: KeyboardEvent<HTMLDivElement>) {
		if (event.key === 'Escape') {
			event.stopPropagation();
		}
	}

	renderPicker() {
		const {
			accountId,
			isAdmin,
			intl: { formatMessage },
		} = this.props;
		const { selectedType, selectedGroup, selectedProject, selectedRole, selectedUser } = this.state;

		switch (selectedType) {
			case GROUP_SHARE:
				return (
					<GroupPicker
						placeholder={formatMessage(messages.groupPlaceholderText)}
						baseUrl=""
						// @ts-expect-error - Type '(value: SharePermissionGroup) => void' is not assignable to type '(arg1: Group | undefined, args_1: UIAnalyticsEvent) => void'.
						onChange={this.onGroupChange}
						fetchOnce={false}
						withMember={isAdmin ? undefined : accountId || undefined}
						selectedGroup={selectedGroup}
						width={gridSize * 40}
						isCompact
						onKeyDown={this.onKeyDown}
					/>
				);
			case USER_SHARE:
				return (
					<UserPicker
						placeholder={formatMessage(messages.userPlaceholderText)}
						baseUrl=""
						// @ts-expect-error - Type '(value: SharePermissionUser) => void' is not assignable to type '(arg1: User | undefined, args_1: UIAnalyticsEvent) => void'.
						onChange={this.onUserChange}
						selectedUser={selectedUser}
						// @ts-expect-error - Type 'number' is not assignable to type 'string'.
						width={gridSize * 40}
						isCompact
						onKeyDown={this.onKeyDown}
					/>
				);
			case PROJECT_SHARE:
				return (
					<>
						<ColumnWrapper>
							<ProjectPicker
								placeholder={formatMessage(messages.projectPlaceholderText)}
								baseUrl=""
								// @ts-expect-error - Type '(value?: PickedProject) => void' is not assignable to type '(arg1: Project | undefined, args_1: UIAnalyticsEvent) => void'.
								onChange={this.onProjectChange}
								selectedProject={mapToPickerProject(selectedProject)}
								// @ts-expect-error - Type 'number' is not assignable to type 'string'.
								width={gridSize * 20}
								isCompact
								requireBrowsePermission={!isAdmin}
								onKeyDown={this.onKeyDown}
							/>
						</ColumnWrapper>
						{selectedProject ? (
							/* passing key to rerender and therefore refetch options when new project is selected  */
							<ColumnWrapper>
								<RolePicker
									// @ts-expect-error - TS2339 - Property 'id' does not exist on type 'never'.
									key={selectedProject.id}
									placeholder={formatMessage(messages.rolePlaceholderText)}
									baseUrl=""
									// @ts-expect-error - TS2339 - Property 'id' does not exist on type 'never'.
									projectId={selectedProject.id}
									// @ts-expect-error - Type '(role?: SharePermissionProjectRole) => void' is not assignable to type '(arg1: Role | undefined, args_1: UIAnalyticsEvent) => void'.
									onChange={this.onRoleChange}
									selectedRole={selectedRole}
									width={gridSize * 20}
									isCompact
									isCurrentMember={!isAdmin}
									onKeyDown={this.onKeyDown}
								/>
							</ColumnWrapper>
						) : null}
					</>
				);
			default:
				return null;
		}
	}

	render() {
		const {
			intl: { formatMessage },
			options,
			isShareScopeLoading,
			isEditorList,
			ariaLabel,
			ariaLabelledby,
		} = this.props;

		const isOnlyPublicAndOrganization =
			options.length === 2 &&
			options.every((i) => i.value === OPEN_SHARE || i.value === PUBLIC_SHARE);

		const { selectedType } = this.state;

		const shareTypeHelperMessage = this.getShareTypeHelperMessage();

		const optionsWithLabelsForScreenReader = this.getOptionsWithHelperMessage();

		const content = (
			<>
				<PickerContainer>
					<ColumnWrapper>
						{isEditorList ? (
							/* @ts-expect-error - Property 'isEditorList' is missing in type '{ ariaLabel: string | undefined; ariaLabelledby: string | undefined; type: ShareType; isShareScopeLoading: boolean; options: Option[]; onChange: (type: ShareType) => void; }' but required in type 'Pick<Omit<{ isShareScopeLoading: boolean; isEditorList: boolean; ariaLabel?: string | undefined; ariaLabelledby?: string | undefined; } & Intl & { type: ShareType; options: Option[]; onChange: (arg1: ShareType, arg2: UIAnalyticsEvent) => void; }, "intl">, "onChange" | ... 5 more ... | "options">'. */
							<EditorTypePicker
								ariaLabel={ariaLabel}
								ariaLabelledby={ariaLabelledby}
								type={selectedType}
								isShareScopeLoading={isShareScopeLoading}
								options={optionsWithLabelsForScreenReader}
								onChange={this.onTypeChange}
							/>
						) : (
							/* @ts-expect-error - Property 'isEditorList' is missing in type '{ ariaLabel: string | undefined; ariaLabelledby: string | undefined; type: ShareType; isShareScopeLoading: boolean; options: Option[]; onChange: (type: ShareType) => void; }' but required in type 'Pick<Omit<{ isShareScopeLoading: boolean; isEditorList: boolean; ariaLabel?: string | undefined; ariaLabelledby?: string | undefined; } & Intl & { type: ShareType; options: Option[]; onChange: (arg1: ShareType, arg2: UIAnalyticsEvent) => void; }, "intl">, "onChange" | ... 5 more ... | "options">'. */
							<ShareeTypePicker
								ariaLabel={ariaLabel}
								ariaLabelledby={ariaLabelledby}
								type={selectedType}
								isShareScopeLoading={isShareScopeLoading}
								options={optionsWithLabelsForScreenReader}
								onChange={this.onTypeChange}
							/>
						)}
					</ColumnWrapper>

					{shareTypeHelperMessage && (
						<ColumnWrapper>
							<HelperMessage>
								<FormattedMessage
									{...shareTypeHelperMessage}
									values={{
										// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
										website: <b>{window.location.host}</b>,
									}}
								/>
							</HelperMessage>
						</ColumnWrapper>
					)}

					{!isShareScopeLoading && this.renderPicker()}
				</PickerContainer>
				<AddButtonWrapper>
					<Button
						isDisabled={isShareScopeLoading}
						onClick={this.onAdd}
						aria-label={formatMessage(isEditorList ? messages.addEditors : messages.addViewers)}
					>
						{isOnlyPublicAndOrganization
							? formatMessage(messages.update)
							: formatMessage(messages.add)}
					</Button>
				</AddButtonWrapper>
			</>
		);

		if (isVisualRefreshEnabled() && fg('visual-refresh_drop_2')) {
			return <Flex alignItems="center">{content}</Flex>;
		}

		return <ShareePickerContainer>{content}</ShareePickerContainer>;
	}
}

export default injectIntl(ShareePicker);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ShareePickerContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	paddingTop: token('space.100', '8px'),
	paddingRight: 0,
	paddingBottom: token('space.100', '8px'),
	paddingLeft: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PickerContainer = styled.div({
	flex: '1 1 auto',
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnWrapper = styled.div({
	marginRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AddButtonWrapper = styled.div({
	flex: '0 0 auto',
});
