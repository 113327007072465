/** @jsx jsx */
import React, { useCallback, useMemo } from 'react';
import { css, jsx } from '@compiled/react';
import { Box } from '@atlaskit/primitives';
import { useSelectedConfigTab } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/controllers/use-selected-config-tab/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { MenuItem } from './menu-item/index.tsx';
import { useJsmMenuItems, useJswMenuItems } from './utils.tsx';
import MenuOld from './index-old.tsx';

type BaseMenuProps = MenuProps & {
	useMenuItems: typeof useJswMenuItems | typeof useJsmMenuItems;
};

export type MenuProps = {
	isJsm: boolean;
	isUserLocatedBoard: boolean;
	isKanban: boolean;
	currentConfigQuery: string;
};

const BaseMenu = ({
	isUserLocatedBoard,
	isKanban,
	currentConfigQuery,
	useMenuItems,
}: BaseMenuProps) => {
	const menuItems = useMenuItems({ isUserLocatedBoard, isKanban });

	const { setSelectedConfigTab } = useSelectedConfigTab();

	const handleItemClick = useCallback(
		(configQuery: string) => setSelectedConfigTab(configQuery),
		[setSelectedConfigTab],
	);

	const options = useMemo(
		() =>
			menuItems.map(({ id, name, configQuery }) => (
				<MenuItem
					key={id}
					name={name}
					configQuery={configQuery}
					isSelected={currentConfigQuery === configQuery}
					onClick={handleItemClick}
				/>
			)),
		[menuItems, currentConfigQuery, handleItemClick],
	);

	return (
		<Box paddingInlineEnd="space.500" paddingBlockStart="space.250" paddingBlockEnd="space.250">
			<ul css={listStyles}>{options}</ul>
		</Box>
	);
};

const listStyles = css({
	padding: 0,
});

const MenuNew = ({ isJsm, isUserLocatedBoard, isKanban, currentConfigQuery }: MenuProps) => {
	return (
		<BaseMenu
			isJsm={isJsm}
			isUserLocatedBoard={isUserLocatedBoard}
			isKanban={isKanban}
			currentConfigQuery={currentConfigQuery}
			useMenuItems={isJsm ? useJsmMenuItems : useJswMenuItems}
		/>
	);
};

const Menu = componentWithFG('jsm_views_configuration_migration', MenuNew, MenuOld);

export default Menu;
