import React from 'react';
import Breadcrumb from '@atlassian/jira-breadcrumbs/src/breadcrumb/component.tsx';
import ProjectBreadcrumb from '@atlassian/jira-breadcrumbs/src/common-breadcrumbs/project/index.tsx';
import UserBreadcrumb from '@atlassian/jira-breadcrumbs/src/common-breadcrumbs/user/view.tsx';
import type { ProjectBean } from '@atlassian/jira-navigation-apps-resources/src/services/sidebar/project-sidebar-navigation/types.tsx';
import { useBoardAndLocationContext as useJswBoardAndLocationContext } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/controllers/use-board-and-location-context/index.tsx';
import { useIsUserLocatedBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/controllers/use-is-user-located-board/index.tsx';
import { useSettingsContainerContext } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/controllers/use-settings-container-context/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import { useJsmBoardAndLocationContext } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/controllers/use-jsm-board-and-location-context/index.tsx';
import { useIsJSMBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-jsm-board/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { SettingsContainerBreadcrumbsOld } from './index-old.tsx';

type JswBoard = NonNullable<ReturnType<typeof useJswBoardAndLocationContext>['board']>;
type JswBoardOwner = NonNullable<ReturnType<typeof useJswBoardAndLocationContext>['user']>;

type JsmBoard = NonNullable<ReturnType<typeof useJsmBoardAndLocationContext>['board']>;
type JsmBoardOwner = NonNullable<ReturnType<typeof useJsmBoardAndLocationContext>['user']>;

const ProjectBreadcrumbsNew = ({
	project,
	board,
	href,
}: {
	project: ProjectBean;
	board: JswBoard | JsmBoard;
	href: string;
}) => {
	const { boardName: nextBoardName } = useSettingsContainerContext();
	const { key: projectKey, name: projectName, id: projectId, simplified } = project;
	const { name: boardName } = board;

	return (
		<ProjectBreadcrumb
			projectKey={projectKey}
			projectName={projectName}
			projectId={projectId}
			isSimplifiedProject={simplified}
		>
			<Breadcrumb
				breadcrumb={{
					text: nextBoardName ?? boardName ?? '',
					href,
					type: 'boardName',
					analyticsAttributes: {
						nextGenProject: simplified,
						projectConfig: simplified ? 'next-gen' : 'classic',
					},
					analyticsFields: {
						name: 'navigateToSubProject',
						containerId: projectId,
						containerType: 'project',
					},
				}}
			/>
		</ProjectBreadcrumb>
	);
};

const UserBreadcrumbsNew = ({
	user,
	board,
}: {
	user: JswBoardOwner | JsmBoardOwner;
	board: JswBoard | JsmBoard;
}) => {
	const [{ query }] = useRouter();
	const { boardName: nextBoardName } = useSettingsContainerContext();

	const { profileUrl, name: userName, accountId } = user;
	const { name: boardName, id: boardId } = board;

	const isLocationless = !!query.locationless;

	if (isLocationless) {
		return (
			<UserBreadcrumb profileUrl={profileUrl} name={userName}>
				<Breadcrumb
					breadcrumb={{
						text: nextBoardName ?? boardName ?? '',
						href: `/secure/RapidBoard.jspa?rapidView=${boardId}&locationless=true`,
						type: 'boardName',
					}}
				/>
			</UserBreadcrumb>
		);
	}

	return (
		<UserBreadcrumb profileUrl={profileUrl} name={userName}>
			<Breadcrumb
				breadcrumb={{
					text: nextBoardName ?? boardName ?? '',
					href: `/jira/people/${accountId}/boards/${boardId}`,
					type: 'boardName',
				}}
			/>
		</UserBreadcrumb>
	);
};

// This doesn't actually render anything, <Breadcrumb /> just builds a sweet-state trail for the
// <BreadcrumbRenderer /> to read from when rendering the breadcrumbs on the page.
const SettingsBreadcrumbs = ({
	useBoardAndLocationContext,
	isJsmBoard = false,
}: {
	useBoardAndLocationContext:
		| typeof useJswBoardAndLocationContext
		| typeof useJsmBoardAndLocationContext;
	isJsmBoard?: boolean;
}) => {
	const isUserLocated = useIsUserLocatedBoard();
	const { project, user, board, error } = useBoardAndLocationContext();

	if (error || !board) {
		return null;
	}

	if (isUserLocated && user) {
		return <UserBreadcrumbsNew user={user} board={board} />;
	}

	if (project) {
		const { key: projectKey } = project;
		const { id: boardId } = board;
		return (
			<ProjectBreadcrumbsNew
				project={project}
				board={board}
				href={
					isJsmBoard
						? `/jira/servicedesk/projects/${projectKey}/boards/${boardId}`
						: `/jira/software/c/projects/${projectKey}/boards/${boardId}`
				}
			/>
		);
	}

	return null;
};

const SettingsContainerBreadcrumbsNew = () => {
	const isJsmBoard = useIsJSMBoard();

	return (
		<SettingsBreadcrumbs
			useBoardAndLocationContext={
				isJsmBoard ? useJsmBoardAndLocationContext : useJswBoardAndLocationContext
			}
			isJsmBoard={isJsmBoard}
		/>
	);
};

export const SettingsContainerBreadcrumbs = componentWithFG(
	'jsm_views_configuration_migration',
	SettingsContainerBreadcrumbsNew,
	SettingsContainerBreadcrumbsOld,
);
