/** @jsx jsx */
import React, { useCallback, useMemo } from 'react';
import { css, jsx } from '@compiled/react';
import { Box } from '@atlaskit/primitives';
import { useSelectedConfigTab } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/controllers/use-selected-config-tab/index.tsx';
import { MenuItem } from './menu-item/index.tsx';
import { useJswMenuItems } from './utils.tsx';

export type MenuProps = {
	isUserLocatedBoard: boolean;
	isKanban: boolean;
	currentConfigQuery: string;
};

const MenuOld = ({ isUserLocatedBoard, isKanban, currentConfigQuery }: MenuProps) => {
	const menuItems = useJswMenuItems({ isUserLocatedBoard, isKanban });

	const { setSelectedConfigTab } = useSelectedConfigTab();

	const handleItemClick = useCallback(
		(configQuery: string) => setSelectedConfigTab(configQuery),
		[setSelectedConfigTab],
	);

	const options = useMemo(
		() =>
			menuItems.map(({ id, name, configQuery }) => (
				<MenuItem
					key={id}
					name={name}
					configQuery={configQuery}
					isSelected={currentConfigQuery === configQuery}
					onClick={handleItemClick}
				/>
			)),
		[menuItems, currentConfigQuery, handleItemClick],
	);

	return (
		<Box paddingInlineEnd="space.500" paddingBlockStart="space.250" paddingBlockEnd="space.250">
			<ul css={listStyles}>{options}</ul>
		</Box>
	);
};

const listStyles = css({
	padding: 0,
});

export default MenuOld;
