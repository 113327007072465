import parameters from '@atlassian/jira-relay/src/__generated__/dateFieldsPageQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = {
	scenario: string;
	projectARIs: string[];
	configuration: {
		viewId: string;
	};
	includeUserPermissions: boolean;
};

export const dateFieldsPageEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-jsw-board-settings-date-fields" */ './ui/date-fields-page/index.tsx'
		).then((module) => module.DateFieldsSettingsPageContent),
	),
	getPreloadProps: ({
		scenario,
		projectARIs,
		configuration,
		includeUserPermissions,
	}: EntryPointParams) => ({
		queries: {
			queryReference: {
				options: {
					fetchPolicy: 'network-only' as const,
				},
				parameters,
				variables: {
					scenario,
					projectARIs,
					configuration,
					includeUserPermissions,
				},
			},
		},
		extraProps: {
			viewId: configuration.viewId,
		},
	}),
});
