import React, { useCallback, useEffect, useMemo } from 'react';
import traceUFOTransition from '@atlaskit/react-ufo/trace-transition';
import { JiraSoftwareBoardAri } from '@atlassian/ari/jira-software/board';
import { useBoardId } from '@atlassian/jira-board-settings-common/src/common/utils/use-board-id/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { JSErrorPageAsync } from '@atlassian/jira-error-pages/src/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { BoardSettingsQuickFiltersSkeleton } from '@atlassian/jira-skeletons/src/ui/board-settings/BoardSettingsCardLayoutSkeleton.tsx';
import { useOnce } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-once/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	APP_BOUNDARY_ID,
	APP_SOURCE_NAME,
	APP_TEAM_NAME,
	BOARD_SETTINGS_QUICK_FILTERS_UFO_NAME,
} from './common/constants/app.tsx';
import { quickFiltersSettingPageEntryPoint } from './entrypoint.tsx';
import { useViewQuickFiltersExperienceTracker } from './services/experience-trakcer/index.tsx';

export const QuickFiltersSettingsPage = () => {
	const boardId = useBoardId();
	const cloudId = useCloudId();

	if (!boardId) {
		return null;
	}

	return <QuickFiltersSettingsContent boardId={boardId} cloudId={cloudId} />;
};

type Props = {
	boardId: number;
	cloudId: string;
};

const QuickFiltersSettingsContent = ({ boardId, cloudId }: Props) => {
	const boardAri = JiraSoftwareBoardAri.create({
		siteId: cloudId,
		boardId: String(boardId),
	}).toString();

	const entrypointParams = useMemo(
		() => ({
			boardId: boardAri,
			isSimplifiedBoardsM2Enabled: fg('simplified-boards-milestone-2_07hbk'),
			isPerformanceImprovement: fg('csm_and_quick_filter_config_performance'),
		}),
		[boardAri],
	);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		quickFiltersSettingPageEntryPoint,
		entrypointParams,
	);

	const { fireExperienceStart, fireExperienceError } = useViewQuickFiltersExperienceTracker({
		isClassic: true,
	});

	const errorFallback = useCallback(
		({ error }: { error: Error }) => {
			fireExperienceError(error);
			return <JSErrorPageAsync error={error} isFullPage={false} />;
		},
		[fireExperienceError],
	);

	useOnce(fireExperienceStart);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
		return () => {
			entryPointActions.unload();
		};
	}, [entryPointActions]);

	useEffect(() => {
		traceUFOTransition(BOARD_SETTINGS_QUICK_FILTERS_UFO_NAME);
	}, []);

	return (
		<JiraEntryPointContainer
			id={APP_BOUNDARY_ID}
			packageName={APP_SOURCE_NAME}
			teamName={APP_TEAM_NAME}
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={{
				rapidViewId: boardId,
			}}
			fallback={<BoardSettingsQuickFiltersSkeleton />}
			errorFallback={errorFallback}
		/>
	);
};

export default QuickFiltersSettingsPage;
