import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	genericTitle: {
		id: 'software-board-settings-date-fields.common.error.generic-title',
		defaultMessage: 'We couldn’t load your data',
		description: 'Title of the flag shown when try to show trash custom field modal',
	},
	genericDescription: {
		id: 'software-board-settings-date-fields.common.error.generic-description',
		defaultMessage: 'Please refresh the page and try again.',
		description: 'Title of the flag shown when try to show trash custom field modal',
	},
});
