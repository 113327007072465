import React from 'react';
import { SwimlanesSettingsPage } from '@atlassian/jira-software-board-settings-swimlanes/src/async.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';

const LazySwimlanesSettingsSpaOld = () => <SwimlanesSettingsPage />;

const LazySwimlanesSettingsSpaNew = () => (
	<ContextualAnalyticsData sourceName="boardSettingsSwimlanes" sourceType={SCREEN}>
		<FireScreenAnalytics />
		<SwimlanesSettingsPage />
	</ContextualAnalyticsData>
);

export const LazySwimlanesSettingsSpa = componentWithFG(
	'jsm_views_configuration_migration',
	LazySwimlanesSettingsSpaNew,
	LazySwimlanesSettingsSpaOld,
);

export default LazySwimlanesSettingsSpa;
