import {
	type ConfigParamKey,
	SETTINGS_CONFIG,
	boardSettingsQueryConfigParamValues,
} from '../../common/constants.tsx';

export const getIsProjectOnly = (configParam: ConfigParamKey) =>
	!SETTINGS_CONFIG[configParam].userLocatedBoardAllowed;

export const getIsScrumOnly = (configParam: ConfigParamKey) =>
	!SETTINGS_CONFIG[configParam].kanbanAllowed;

export const getIsJswOnly = (configParam: ConfigParamKey) =>
	SETTINGS_CONFIG[configParam].jswAllowed &&
	!SETTINGS_CONFIG[configParam].isJsmFeatureVisible({
		isBoardEnabled: true,
		isCalendarEnabled: true,
	});

export const getIsJsmOnly = (configParam: ConfigParamKey) =>
	!SETTINGS_CONFIG[configParam].jswAllowed &&
	SETTINGS_CONFIG[configParam].isJsmFeatureVisible({
		isBoardEnabled: true,
		isCalendarEnabled: true,
	});

export const isValidConfigParam = (configParam: string): configParam is ConfigParamKey =>
	Object.keys(boardSettingsQueryConfigParamValues).includes(configParam);
