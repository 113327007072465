import React from 'react';
import { styled } from '@compiled/react';
import Heading from '@atlaskit/heading';
import { Stack, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { CompletedIssuesField } from './completed-issues-field/index.tsx';
import messages from './messages.tsx';

export const CompletedIssuesSection = () => {
	const { formatMessage } = useIntl();

	return (
		<UFOSegment name="completed-issues-section">
			<Stack xcss={containerStyles}>
				<Heading size="small" as="h3">
					{formatMessage(messages.title)}
				</Heading>
				<About>{formatMessage(messages.about)}</About>
				<CompletedIssuesField />
			</Stack>
		</UFOSegment>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const About = styled.p({
	width: '660px',
	marginTop: '0',
});

const containerStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	rowGap: 'space.150',
	marginTop: 'space.500',
	marginBottom: 'space.800',
});
