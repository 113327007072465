/**
 * @generated SignedSource<<f2091fa867cf86e37292951316c2a371>>
 * @relayHash 7b6ffad891dfb19326c5200574d36419
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1159e9b3873e98aa8a2b5f17907c41622007ca4a925638c26270b1f578089db3

import type { ConcreteRequest, Query } from 'relay-runtime';
export type quickFilters_RootQuery$variables = {
  boardId: string;
  isCMP: boolean;
  isPerformanceImprovement: boolean;
  isSimplifiedBoardsM2Enabled: boolean;
};
export type quickFilters_RootQuery$data = {
  readonly boardScope: {
    readonly __id: string;
    readonly currentUser?: {
      readonly permissions: ReadonlyArray<AGG$SoftwareBoardPermission | null | undefined>;
    };
    readonly customFilters?: ReadonlyArray<{
      readonly description: string | null | undefined;
      readonly filterQuery: {
        readonly sanitisedJql: string;
      } | null | undefined;
      readonly id: string;
      readonly name: string;
    } | null | undefined>;
    readonly customFiltersConfig?: {
      readonly customFilters: ReadonlyArray<{
        readonly description: string | null | undefined;
        readonly id: string;
        readonly jql: string;
        readonly name: string;
      } | null | undefined>;
    };
  };
};
export type quickFilters_RootQuery = {
  response: quickFilters_RootQuery$data;
  variables: quickFilters_RootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "boardId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isCMP"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isPerformanceImprovement"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isSimplifiedBoardsM2Enabled"
},
v4 = [
  {
    "kind": "Variable",
    "name": "boardId",
    "variableName": "boardId"
  },
  {
    "kind": "Variable",
    "name": "isCMP",
    "variableName": "isCMP"
  }
],
v5 = {
  "condition": "isSimplifiedBoardsM2Enabled",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "concreteType": "CurrentUser",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "permissions"
        }
      ]
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "name": "id"
},
v7 = {
  "kind": "ScalarField",
  "name": "name"
},
v8 = {
  "kind": "ScalarField",
  "name": "description"
},
v9 = {
  "concreteType": "CustomFilter",
  "kind": "LinkedField",
  "name": "customFilters",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    (v7/*: any*/),
    {
      "concreteType": "FilterQuery",
      "kind": "LinkedField",
      "name": "filterQuery",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "sanitisedJql"
        }
      ]
    },
    (v8/*: any*/)
  ]
},
v10 = {
  "concreteType": "CustomFilterV2",
  "kind": "LinkedField",
  "name": "customFilters",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    (v7/*: any*/),
    {
      "kind": "ScalarField",
      "name": "jql"
    },
    (v8/*: any*/)
  ]
},
v11 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "quickFilters_RootQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "args": (v4/*: any*/),
          "concreteType": "BoardScope",
          "kind": "LinkedField",
          "name": "boardScope",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "condition": "isPerformanceImprovement",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": (v9/*: any*/),
                  "action": "THROW",
                  "path": "boardScope.customFilters"
                }
              ]
            },
            {
              "condition": "isPerformanceImprovement",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "concreteType": "CustomFiltersConfig",
                    "kind": "LinkedField",
                    "name": "customFiltersConfig",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": (v10/*: any*/),
                        "action": "THROW",
                        "path": "boardScope.customFiltersConfig.customFilters"
                      }
                    ]
                  },
                  "action": "THROW",
                  "path": "boardScope.customFiltersConfig"
                }
              ]
            },
            (v11/*: any*/)
          ]
        },
        "action": "THROW",
        "path": "boardScope"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "quickFilters_RootQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "concreteType": "BoardScope",
        "kind": "LinkedField",
        "name": "boardScope",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "condition": "isPerformanceImprovement",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              (v9/*: any*/)
            ]
          },
          {
            "condition": "isPerformanceImprovement",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "concreteType": "CustomFiltersConfig",
                "kind": "LinkedField",
                "name": "customFiltersConfig",
                "plural": false,
                "selections": [
                  (v10/*: any*/)
                ]
              }
            ]
          },
          (v6/*: any*/),
          (v11/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "1159e9b3873e98aa8a2b5f17907c41622007ca4a925638c26270b1f578089db3",
    "metadata": {},
    "name": "quickFilters_RootQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "b187e0517701a974cd27bb594979a173";

export default node;
