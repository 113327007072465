import React from 'react';
import Breadcrumb from '@atlassian/jira-breadcrumbs/src/breadcrumb/component.tsx';
import ProjectBreadcrumb from '@atlassian/jira-breadcrumbs/src/common-breadcrumbs/project/index.tsx';
import UserBreadcrumb from '@atlassian/jira-breadcrumbs/src/common-breadcrumbs/user/view.tsx';
import type { ProjectBean } from '@atlassian/jira-navigation-apps-resources/src/services/sidebar/project-sidebar-navigation/types.tsx';
import { useBoardAndLocationContext } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/controllers/use-board-and-location-context/index.tsx';
import { useIsUserLocatedBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/controllers/use-is-user-located-board/index.tsx';
import { useSettingsContainerContext } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/controllers/use-settings-container-context/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';

type Board = NonNullable<ReturnType<typeof useBoardAndLocationContext>['board']>;
type BoardOwner = NonNullable<ReturnType<typeof useBoardAndLocationContext>['user']>;

export const ProjectBreadcrumbsOld = ({
	project,
	board,
}: {
	project: ProjectBean;
	board: Board;
}) => {
	const { boardName: nextBoardName } = useSettingsContainerContext();
	const { key: projectKey, name: projectName, id: projectId, simplified } = project;
	const { name: boardName, id: boardId } = board;

	return (
		<ProjectBreadcrumb
			projectKey={projectKey}
			projectName={projectName}
			projectId={projectId}
			isSimplifiedProject={simplified}
		>
			<Breadcrumb
				breadcrumb={{
					text: nextBoardName ?? boardName,
					href: `/jira/software/c/projects/${projectKey}/boards/${boardId}`,
					type: 'boardName',
					analyticsAttributes: {
						nextGenProject: simplified,
						projectConfig: simplified ? 'next-gen' : 'classic',
					},
					analyticsFields: {
						name: 'navigateToSubProject',
						containerId: projectId,
						containerType: 'project',
					},
				}}
			/>
		</ProjectBreadcrumb>
	);
};

export const UserBreadcrumbsOld = ({ user, board }: { user: BoardOwner; board: Board }) => {
	const [{ query }] = useRouter();
	const { boardName: nextBoardName } = useSettingsContainerContext();

	const { profileUrl, name: userName, accountId } = user;
	const { name: boardName, id: boardId } = board;

	const isLocationless = !!query.locationless;

	if (isLocationless) {
		return (
			<UserBreadcrumb profileUrl={profileUrl} name={userName}>
				<Breadcrumb
					breadcrumb={{
						text: nextBoardName ?? boardName,
						href: `/secure/RapidBoard.jspa?rapidView=${boardId}&locationless=true`,
						type: 'boardName',
					}}
				/>
			</UserBreadcrumb>
		);
	}

	return (
		<UserBreadcrumb profileUrl={profileUrl} name={userName}>
			<Breadcrumb
				breadcrumb={{
					text: nextBoardName ?? boardName,
					href: `/jira/people/${accountId}/boards/${boardId}`,
					type: 'boardName',
				}}
			/>
		</UserBreadcrumb>
	);
};

// This doesn't actually render anything, <Breadcrumb /> just builds a sweet-state trail for the
// <BreadcrumbRenderer /> to read from when rendering the breadcrumbs on the page.
export const SettingsContainerBreadcrumbsOld = () => {
	const isUserLocated = useIsUserLocatedBoard();
	const { project, user, board, error } = useBoardAndLocationContext();

	if (error || !board) {
		return null;
	}

	if (isUserLocated && user) {
		return <UserBreadcrumbsOld user={user} board={board} />;
	}

	if (project) {
		return <ProjectBreadcrumbsOld project={project} board={board} />;
	}

	return null;
};
