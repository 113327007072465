import React from 'react';
import { TimelineSettingsPage } from '@atlassian/jira-software-board-settings-timeline/src/async.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';

const LazyTimelineSettingsSpaOld = () => <TimelineSettingsPage />;

const LazyTimelineSettingsSpaNew = () => (
	<ContextualAnalyticsData sourceName="boardSettingsTimelineConfig" sourceType={SCREEN}>
		<FireScreenAnalytics />
		<TimelineSettingsPage />
	</ContextualAnalyticsData>
);

const LazyTimelineSettingsSpa = componentWithFG(
	'jsm_views_configuration_migration',
	LazyTimelineSettingsSpaNew,
	LazyTimelineSettingsSpaOld,
);

export default LazyTimelineSettingsSpa;
