import React, { useCallback, useEffect } from 'react';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import type { QueryItem } from '@atlassian/jira-software-board-settings-query-list-config-common/src/common/types/index.tsx';
import { SuccessFlagWithBoardLink } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/success-flag-with-board-link/index.tsx';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller/src/index.tsx';
import { BOARD_SETTINGS_DELETE_SWIMLANE_MODAL_ID } from '../../../common/constants/modals.tsx';
import { useDeleteSwimlane } from '../../../services/delete-swimlane/index.tsx';
import { useRapidViewId, useIsCMPBoard } from '../../selectors/board/index.tsx';
import { useSwimlanesWithoutDefault, useItemToDelete } from '../../selectors/swimlanes/index.tsx';
import messages from './messages.tsx';

export const useDeleteSwimlaneHandler = () => {
	const [rapidViewId] = useRapidViewId();
	const [isCMPBoard] = useIsCMPBoard();
	const [, { deleteSwimlane }] = useSwimlanesWithoutDefault();
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();
	const [isDeleteModalOpen, { on: openDeleteModal, off: closeDeleteModal }] = useSwitchModals(
		BOARD_SETTINGS_DELETE_SWIMLANE_MODAL_ID,
	);

	const { commit: commitDeleteSwimlane, loading: isDeleteSubmitting } = useDeleteSwimlane(
		rapidViewId,
		formatMessage(messages.failedToDeleteErrorTitle),
	);

	const [itemToDelete, { setItemToDelete }] = useItemToDelete();

	const handleItemDelete = useCallback(
		(queryItem: QueryItem) => {
			setItemToDelete(queryItem);
			openDeleteModal();
		},
		[openDeleteModal, setItemToDelete],
	);

	const handleItemDeleteConfirm = useCallback(
		async (swimlaneToDelete: QueryItem) => {
			const swimlaneId = Number(swimlaneToDelete.id);
			const result = await commitDeleteSwimlane(swimlaneId);
			if (result === null) {
				deleteSwimlane(swimlaneId);
				showFlag((props) => (
					<SuccessFlagWithBoardLink
						isCMPBoard={isCMPBoard}
						title={formatMessage(messages.successFlagForDeletingSwimlane)}
						{...props}
					/>
				));
			}

			setItemToDelete(null);
			closeDeleteModal();
		},
		[
			commitDeleteSwimlane,
			deleteSwimlane,
			formatMessage,
			isCMPBoard,
			showFlag,
			setItemToDelete,
			closeDeleteModal,
		],
	);

	useEffect(() => {
		if (!isDeleteModalOpen) {
			// reset itemToDelete state of modal is closed
			setItemToDelete(null);
		}
	}, [isDeleteModalOpen, setItemToDelete]);

	return {
		handleItemDelete,
		handleItemDeleteConfirm,
		isDeleteSubmitting,
		itemToDelete,
	};
};
