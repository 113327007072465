import React from 'react';
import { WorkingDaysSettingsPage as WorkingDaysSettingsSpa } from '@atlassian/jira-software-board-settings-working-days/src/async.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';

const LazyWorkingDaysSettingsSpaOld = () => <WorkingDaysSettingsSpa />;

const LazyWorkingDaysSettingsSpaNew = () => (
	<ContextualAnalyticsData sourceName="boardSettingsTime" sourceType={SCREEN}>
		<FireScreenAnalytics />
		<WorkingDaysSettingsSpa />
	</ContextualAnalyticsData>
);

const LazyWorkingDaysSettingsSpa = componentWithFG(
	'jsm_views_configuration_migration',
	LazyWorkingDaysSettingsSpaNew,
	LazyWorkingDaysSettingsSpaOld,
);

export default LazyWorkingDaysSettingsSpa;
