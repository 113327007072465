import React from 'react';
import { useIsJSMBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-jsm-board/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { BoardAdministratorField } from './board-administrator-field/index.tsx';
import { BoardLocationField } from './board-location-field/index.tsx';
import { BoardNameField } from './board-name-field/index.tsx';

const GeneralSettingFields = () => {
	const isJSMBoard = useIsJSMBoard();

	return (
		<UFOSegment name="general-settings-fields">
			<BoardNameField />
			<BoardAdministratorField />
			{!isJSMBoard && <BoardLocationField />}
		</UFOSegment>
	);
};

export { GeneralSettingFields };
