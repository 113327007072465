import React, { Component } from 'react';
import { v4 as uuid } from 'uuid';
import PeopleGroupIcon from '@atlaskit/icon/glyph/people-group';
import { PickerWithAvatar } from '@atlassian/jira-common-components-picker/src/index.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { Group, Props, State } from '../model/index.tsx';
import { getAllGroups, getUserGroups } from '../services/index.tsx';
import messages from './messages.tsx';

// eslint-disable-next-line jira/react/no-class-components
export class GroupPicker extends Component<Props, State> {
	static defaultProps = {
		isRequired: false,
		isDisabled: false,
		isClearable: true,
		isCompact: false,
		fetchOnce: false,
		width: 200,
	};

	constructor(props: Props) {
		super(props);
		this.groupPickerId = `group-picker.label.id-${uuid()}`;
	}

	static getDerivedStateFromProps(props: Props, state: State) {
		const currentGroup = props.selectedGroup && props.selectedGroup.value;
		const prevGroup = state.prevSelectedGroup && state.prevSelectedGroup.value;

		if (currentGroup !== prevGroup) {
			return {
				...state,
				selectedGroup: props.selectedGroup,
				prevSelectedGroup: props.selectedGroup,
			};
		}

		return null;
	}

	state = {
		options: [],
		selectedGroup: this.props.selectedGroup,
		prevSelectedGroup: this.props.selectedGroup,
	};

	componentDidMount() {
		const { fetchOnce } = this.props;

		if (fetchOnce === true) {
			const getOptions = this.getFetchOptionsFunction();

			getOptions('').then((groups) =>
				this.setState({
					// @ts-expect-error - TS2322 - Type 'void | Group[]' is not assignable to type 'Group[]'.
					options: groups,
				}),
			);
		}
	}

	groupPickerId;

	onSelected = (selectedOption?: Group) => {
		this.setState({ selectedGroup: selectedOption });
		this.props.onChange(selectedOption);
	};

	getFetchOptionsFunction() {
		return this.props.withMember ? this.fetchUserGroups : this.fetchAllGroups;
	}

	/* eslint-disable jira/react-arrow-function-property-naming */
	formatOptionDataTransformer = (group: Group) => ({
		// @ts-expect-error - TS2769 - No overload matches this call.
		avatar: <PeopleGroupIcon size="small" />,
		label: group.name,
	});

	/* eslint-disable jira/react-arrow-function-property-naming */
	fetchAllGroups = (searchQuery: string) => getAllGroups(this.props.baseUrl, searchQuery);

	/* eslint-disable jira/react-arrow-function-property-naming */
	fetchUserGroups = (searchQuery: string) =>
		this.props.withMember
			? getUserGroups(this.props.baseUrl, this.props.withMember, searchQuery)
			: Promise.resolve();

	render() {
		const {
			intl: { formatMessage },
			placeholder = this.props.placeholder || formatMessage(messages.placeholder),
			onChange,
			fetchOnce,
			...props
		} = this.props;

		const { selectedGroup } = this.state;

		return (
			<PickerWithAvatar
				aria-label={!this.props.label ? formatMessage(messages.labelText) : undefined}
				inputId={this.groupPickerId}
				{...props}
				errorMessage={formatMessage(messages.error)}
				placeholder={placeholder}
				onSelected={this.onSelected}
				// @ts-expect-error - TS2769 - No overload matches this call.
				options={fetchOnce === true ? this.state.options : this.getFetchOptionsFunction()}
				formatOptionDataTransformer={this.formatOptionDataTransformer}
				value={selectedGroup && selectedGroup.name ? selectedGroup : null}
			/>
		);
	}
}

export default injectIntl(GroupPicker);
