import React, {
	type KeyboardEvent as ReactKeyBoardEvent,
	useCallback,
	useRef,
	useLayoutEffect,
} from 'react';
import { styled } from '@compiled/react';
import type { CustomTriggerProps } from '@atlaskit/dropdown-menu';
import FocusRing from '@atlaskit/focus-ring';
import DragHandlerIcon from '@atlaskit/icon/glyph/drag-handler';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import useMergeRefs from '@atlassian/jira-merge-refs/src/index.tsx';
import { dataTestIds } from '../../../../common/constants/data-test.tsx';
import messages from './messages.tsx';

type Props = CustomTriggerProps<HTMLElement> & {
	name: string;
};

export const DragHandleButton = (props: Props) => {
	const dragHandleButtonRef = useRef<HTMLDivElement>(null);
	const { formatMessage } = useIntl();

	const { onClick: onDragHandleClick, triggerRef, isSelected } = props;
	const { name, ...triggerProps } = props;

	const ref = useMergeRefs(dragHandleButtonRef, triggerRef);

	// Need to useLayoutEffect to capture and prevent the keydown for drag handle button before DynamicTable capture it
	// This is important to support keyboard
	useLayoutEffect(() => {
		const handler = (e: KeyboardEvent) => {
			if (e.key !== ' ' && e.key !== 'Enter') {
				return;
			}

			// Only preventDefault if target matches the current button
			if (e.target === dragHandleButtonRef.current) {
				e.preventDefault();
			}
		};

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('keydown', handler, true);
		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('keydown', handler, true);
		};
	}, []);

	const handkeKeyDown = useCallback(
		(e: ReactKeyBoardEvent<HTMLDivElement>) => {
			if (onDragHandleClick && (e.key === 'Enter' || e.key === ' ')) {
				e.stopPropagation();
				e.preventDefault();
				onDragHandleClick(e);
			}
		},
		[onDragHandleClick],
	);

	return (
		<FocusRing>
			{/* Dynamic Table does not allow drag event with interactive element so we use a div with role button for drag handle icon */}
			<DragHandleButtonContainer
				role="button"
				tabIndex={0}
				{...triggerProps}
				onKeyDown={handkeKeyDown}
				ref={ref}
				isDragContextMenuOpen={!!isSelected}
				data-testid={dataTestIds.queryListDragHandle}
			>
				<DragHandlerIcon
					size="medium"
					label={formatMessage(messages.dragHandleLabel, {
						name,
					})}
				/>
			</DragHandleButtonContainer>
		</FocusRing>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DragHandleButtonContainer = styled.div<{ isDragContextMenuOpen: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.neutral.subtle.hovered', colors.N30),
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.neutral.subtle.pressed', colors.N40),
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isDragContextMenuOpen }) =>
		isDragContextMenuOpen
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.background.selected', colors.B50)
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.background.neutral.subtle', colors.N0),

	borderRadius: token('border.radius.100', '3px'),
	paddingTop: token('space.0', '0px'),
	paddingRight: token('space.0', '0px'),
	paddingBottom: token('space.0', '0px'),
	paddingLeft: token('space.0', '0px'),
	width: 'max-content',
	border: 'none',
	cursor: 'grab',
	display: 'flex',
});
