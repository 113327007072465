import React from 'react';
import { CardColorsSettingsPage } from '@atlassian/jira-software-board-settings-card-colors/src/async.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';

const LazyCardColoursSettingsSpaOld = () => <CardColorsSettingsPage />;

const LazyCardColoursSettingsSpaNew = () => (
	<ContextualAnalyticsData sourceName="boardSettingsCardColors" sourceType={SCREEN}>
		<FireScreenAnalytics />
		<CardColorsSettingsPage />
	</ContextualAnalyticsData>
);
const LazyCardColoursSettingsSpa = componentWithFG(
	'jsm_views_configuration_migration',
	LazyCardColoursSettingsSpaNew,
	LazyCardColoursSettingsSpaOld,
);

export default LazyCardColoursSettingsSpa;
