import React from 'react';
import { ColumnSettingsMappingPageAsync } from '@atlassian/jira-software-board-settings-columns/src/async.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';

const LazyColumnsSettingsSpaOld = () => <ColumnSettingsMappingPageAsync />;

const LazyColumnsSettingsSpaNew = () => (
	<ContextualAnalyticsData sourceName="boardSettingsColumns" sourceType={SCREEN}>
		<FireScreenAnalytics />
		<ColumnSettingsMappingPageAsync />
	</ContextualAnalyticsData>
);

const LazyColumnsSettingsSpa = componentWithFG(
	'jsm_views_configuration_migration',
	LazyColumnsSettingsSpaNew,
	LazyColumnsSettingsSpaOld,
);

export default LazyColumnsSettingsSpa;
