/** @jsx jsx */
import React, { useCallback, useMemo } from 'react';
import { css, jsx } from '@compiled/react';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useRouter, Link } from '@atlassian/react-resource-router';

type Props = {
	name: string;
	configQuery: string;
	isSelected: boolean;
	onClick: (configQuery: string) => void;
};

export const MenuItem = ({ name, configQuery, isSelected, onClick }: Props) => {
	const [{ query, location }] = useRouter();

	const handleClick = useCallback(() => onClick(configQuery), [onClick, configQuery]);

	const queryParams = useMemo(
		() =>
			new URLSearchParams({
				...query,
				config: configQuery,
			}),
		[query, configQuery],
	);

	return (
		<li css={listItemStyles}>
			<Link
				css={[baseStyles, isSelected ? selectedStyle : notSelectedStyle]}
				onClick={handleClick}
				href={`${location.pathname}?${queryParams.toString()}`}
				aria-current={isSelected ? 'page' : undefined}
			>
				{name}
			</Link>
		</li>
	);
};

const baseStyles = css({
	display: 'block',
	width: '100%',
	boxSizing: 'border-box',
	paddingTop: token('space.075', '6px'),
	paddingRight: token('space.150', '12px'),
	paddingBottom: token('space.075', '6px'),
	paddingLeft: token('space.150', '12px'),
	'&:active': {
		outline: 'none',
		boxShadow: `0 0 0 2px ${token('color.text.selected')} inset`,
		textDecoration: 'none',
	},
	'&:focus': {
		outline: 'none',
		boxShadow: `0 0 0 2px ${token('color.text.selected')} inset`,
		textDecoration: 'none',
	},
	'&:hover': {
		cursor: 'pointer',
		textDecoration: 'none',
	},
	border: 'none',
	whiteSpace: 'nowrap',
	borderRadius: token('space.050', '4px'),
	textAlign: 'left',
});

const notSelectedStyle = css({
	backgroundColor: token('color.background.input', N0),
	color: token('color.text'),
	'&:hover': {
		backgroundColor: token('color.background.selected'),
		color: token('color.text.selected'),
	},
});

const selectedStyle = css({
	backgroundColor: token('color.background.selected'),
	color: token('color.text.selected'),
	position: 'relative',
	'&::before': {
		width: token('space.050', '4px'),
		position: 'absolute',
		top: token('space.100', '8px'),
		bottom: token('space.100', '8px'),
		left: 0,
		backgroundColor: token('color.text.selected'),
		borderRadius: `0 ${token('space.050', '4px')} ${token('space.050', '4px')} 0`,
		content: '',
	},
});

const listItemStyles = css({
	listStyleType: 'none',
	display: 'block',
	padding: 0,
	margin: 0,
});
