import React, { useCallback, useMemo } from 'react';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { ErrorAutoDismissFlag } from '@atlassian/jira-flags/src/common/ui/components'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import {
	useOldDoneIssuesCutoff,
	useOldDoneIssuesCutoffOptions as useOldDoneIssuesCutoffOptionsState,
} from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board-fields/index.tsx';
import {
	useIsCMPBoard,
	useRapidViewId,
} from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board/index.tsx';
import type { CutoffOption } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/index.tsx';
import { useFireAnalyticsTrackEvent } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-fire-analytics-event/index.tsx';
import { SuccessFlagWithBoardLink } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/success-flag-with-board-link/index.tsx';
import { useUpdateCompletedIssues } from '../../../services/update-completed-issues/index.tsx';
import messages from './messages.tsx';

export const useCompletedIssues = () => {
	const { formatMessage } = useIntl();
	const { fireTrackEvent } = useFireAnalyticsTrackEvent();
	const { showFlag } = useFlagService();

	const isCMPBoard = useIsCMPBoard();
	const rapidViewId = useRapidViewId();
	const { commit: updateCompletedIssues } = useUpdateCompletedIssues();

	const [oldDoneIssuesCutoff, { updateOldDoneIssueCutoff }] = useOldDoneIssuesCutoff();
	const [oldDoneIssuesCutoffOptions] = useOldDoneIssuesCutoffOptionsState();

	const currentOldDoneIssuesCutoff = useMemo(
		() => oldDoneIssuesCutoffOptions.find(({ value }) => value === oldDoneIssuesCutoff) ?? null,
		[oldDoneIssuesCutoffOptions, oldDoneIssuesCutoff],
	);

	const onUpdate = useCallback(
		async (nextOldDoneIssuesCutoff: string) => {
			updateOldDoneIssueCutoff(nextOldDoneIssuesCutoff);

			const response = await updateCompletedIssues({
				rapidViewId,
				oldDoneIssuesCutoff: nextOldDoneIssuesCutoff,
			});

			if (response.ok) {
				showFlag((props) => (
					<SuccessFlagWithBoardLink
						isCMPBoard={isCMPBoard}
						title={formatMessage(messages.updateCompletedIssuesSuccessfulTitle)}
						{...props}
					/>
				));
				fireTrackEvent('updateCompletedIssues succeeded');
			} else {
				updateOldDoneIssueCutoff(oldDoneIssuesCutoff);
				showFlag((props) => (
					<ErrorAutoDismissFlag
						title={formatMessage(messages.updateCompletedIssuesFailureTitle)}
						description={formatMessage(messages.updateCompletedIssuesFailureDescription)}
						{...props}
					/>
				));
				fireTrackEvent('updateCompletedIssues failed');
			}
		},
		[
			oldDoneIssuesCutoff,
			rapidViewId,
			showFlag,
			updateOldDoneIssueCutoff,
			updateCompletedIssues,
			isCMPBoard,
			formatMessage,
			fireTrackEvent,
		],
	);

	const onChange = useCallback(
		async (option: CutoffOption | null) => {
			option?.value && option.value !== oldDoneIssuesCutoff && (await onUpdate(option.value));
		},
		[onUpdate, oldDoneIssuesCutoff],
	);

	return {
		oldDoneIssuesCutoffOptions,
		currentOldDoneIssuesCutoff,
		onChange,
	};
};
