import { useMemo } from 'react';
import { useMaybeConfigParam } from '../use-config-param/index.tsx';

export const useIsBoardSettingsTabOpen = (definedTabParamValue: string) => {
	const configParam = useMaybeConfigParam() ?? '';

	return useMemo(
		() =>
			// compare case-insensitive between definedTabParamValue with router `config` query param to check if a board settings tab is open
			definedTabParamValue.localeCompare(configParam, undefined, {
				sensitivity: 'base',
			}) === 0,
		[configParam, definedTabParamValue],
	);
};
