import React, { useCallback, useMemo, type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import { v4 as uuid } from 'uuid';
import Heading from '@atlaskit/heading';
import { Text } from '@atlaskit/primitives';
import { RadioGroup } from '@atlaskit/radio';
import type { OptionsPropType } from '@atlaskit/radio/types';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { SwimlaneStrategy } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/swimlanes.tsx';
import { dataTestIds } from '../../../../common/constants/data-test.tsx';
import type { SwimlaneStrategyTransition } from '../../../../common/types/index.tsx';
import { useCanEdit } from '../../../../controllers/selectors/permissions/index.tsx';
import messages from './messages.tsx';

export const AssigneeStrategy = ({
	transition,
	onChange,
}: {
	transition: SwimlaneStrategyTransition;
	onChange(value: SwimlaneStrategy): void;
}) => {
	const { formatMessage } = useIntl();
	const labelledBy = useMemo(() => `radio-group-label-${uuid()}`, []);
	const [canEdit] = useCanEdit();

	const onChangeInternal = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			if (isValid(e.target.value)) onChange(e.target.value);
		},
		[onChange],
	);

	const options = useMemo<OptionsPropType>(
		() => [
			{
				name: 'show-below',
				value: SwimlaneStrategy.assigneeUnassignedLast,
				label: formatMessage(messages.showBelow),
			},
			{
				name: 'show-above',
				value: SwimlaneStrategy.assigneeUnassignedFirst,
				label: formatMessage(messages.showAbove),
			},
		],
		[formatMessage],
	);

	const value = transition.next ?? transition.current;
	const loading = transition.kind === 'in-progress';

	return (
		<Container>
			<div>
				<Heading size="small" as="h3" id={labelledBy}>
					{formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.titleIssueTermRefresh
							: messages.title,
					)}
				</Heading>
				<Text as="p">
					{formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.descriptionIssueTermRefresh
							: messages.description,
					)}
				</Text>
			</div>
			<div data-testid={dataTestIds.unassignedIssuesRadioGroup}>
				<RadioGroup
					aria-labelledby={labelledBy}
					onChange={onChangeInternal}
					isDisabled={!canEdit || loading}
					options={options}
					value={value}
				/>
			</div>
		</Container>
	);
};

const isValid = (
	input: string,
): input is
	| typeof SwimlaneStrategy.assigneeUnassignedFirst
	| typeof SwimlaneStrategy.assigneeUnassignedLast => {
	switch (input) {
		case SwimlaneStrategy.assigneeUnassignedFirst:
		case SwimlaneStrategy.assigneeUnassignedLast:
			return true;
		default:
			return false;
	}
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.200', '16px'),
});
