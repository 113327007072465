import query from '@atlassian/jira-relay/src/__generated__/columnsSettingsPageNewQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = { boardId: string; cloudId: string };
export const columnsSettingPageNewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-jsw-board-settings-columns" */ './index.tsx').then(
			(module) => module.ColumnsSettingPageContent,
		),
	),
	getPreloadProps: ({ boardId, cloudId }: EntryPointParams) => ({
		queries: {
			queryReference: {
				options: {
					fetchPolicy: 'network-only' as const,
				},
				parameters: query,
				variables: {
					boardId,
					cloudId,
				},
			},
		},
	}),
});
