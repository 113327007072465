import React, { type ReactNode, useMemo } from 'react';
import SettingsContainer from '@atlassian/jira-software-board-settings-container/src/ui/index.tsx';
import { boardSettingsQueryConfigParamValues } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import { useIsKanbanBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/controllers/use-is-kanban-board/index.tsx';
import { useIsUserLocatedBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/controllers/use-is-user-located-board/index.tsx';
import { useIsJSMBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-jsm-board/index.tsx';
import { LastOpenedConfigTab } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/last-opened-config-tab/index.tsx';
import RapidBoardCardColoursSettings from '@atlassian/jira-spa-apps-classic-board-settings-card-colours/src/index.tsx';
import RapidBoardCardLayoutSettings from '@atlassian/jira-spa-apps-classic-board-settings-card-layout/src/index.tsx';
import RapidBoardColumnsSettings from '@atlassian/jira-spa-apps-classic-board-settings-columns/src/index.tsx';
import RapidBoardEstimationSettings from '@atlassian/jira-spa-apps-classic-board-settings-estimation/src/index.tsx';
import RapidBoardGeneralSettings from '@atlassian/jira-spa-apps-classic-board-settings-general/src/index.tsx';
import RapidBoardQuickFiltersSettings from '@atlassian/jira-spa-apps-classic-board-settings-quick-filters/src/index.tsx';
import RapidBoardSwimlanesSettings from '@atlassian/jira-spa-apps-classic-board-settings-swimlanes/src/index.tsx';
import RapidBoardTimelinesSettings from '@atlassian/jira-spa-apps-classic-board-settings-timeline/src/index.tsx';
import RapidBoardWorkingDaysSettings from '@atlassian/jira-spa-apps-classic-board-settings-working-days/src/index.tsx';
import { DateFieldsSettingsPage as RapidBoardDateFieldsSettings } from '@atlassian/jira-software-board-settings-date-fields/src/async.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';

/**
 *
 * @deprecated
 */
export const BoardConfigSwitcher = () => {
	const [{ query }] = useRouter();
	const isUserLocatedBoard = useIsUserLocatedBoard();
	const isKanban = useIsKanbanBoard();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const isJsm = fg('jsm_views_configuration_migration') ? useIsJSMBoard() : false;

	const configToPageMap: Record<string, ReactNode> = useMemo(
		() => ({
			[boardSettingsQueryConfigParamValues.filter]: <RapidBoardGeneralSettings />,
			[boardSettingsQueryConfigParamValues.columns]: <RapidBoardColumnsSettings />,
			[boardSettingsQueryConfigParamValues.swimlanes]: <RapidBoardSwimlanesSettings />,
			[boardSettingsQueryConfigParamValues.quickFilters]: <RapidBoardQuickFiltersSettings />,
			[boardSettingsQueryConfigParamValues.cardColors]: <RapidBoardCardColoursSettings />,
			[boardSettingsQueryConfigParamValues.cardLayout]: <RapidBoardCardLayoutSettings />,
			...(!isJsm && {
				[boardSettingsQueryConfigParamValues.time]: <RapidBoardWorkingDaysSettings />,
			}),
			...(!isKanban &&
				!isJsm && {
					[boardSettingsQueryConfigParamValues.estimation]: <RapidBoardEstimationSettings />,
				}),
			...(!isUserLocatedBoard &&
				!isJsm && {
					[boardSettingsQueryConfigParamValues.timelineConfig]: <RapidBoardTimelinesSettings />,
				}),
			...(isJsm && {
				[boardSettingsQueryConfigParamValues.dateFields]: <RapidBoardDateFieldsSettings />,
			}),
		}),
		[isKanban, isUserLocatedBoard, isJsm],
	);

	const ConfigPage = useMemo(() => configToPageMap[query.config], [configToPageMap, query.config]);

	return ConfigPage ? (
		<SettingsContainer packageId={`jira-board-settings.${query.config}-settings-route`}>
			{ConfigPage}
		</SettingsContainer>
	) : (
		<LastOpenedConfigTab
			configParam={query.config}
			isUserLocatedBoard={isUserLocatedBoard}
			isKanban={isKanban}
			isJsm={isJsm}
		/>
	);
};
