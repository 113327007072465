import React, { useEffect, useMemo } from 'react';
import traceUFOTransition from '@atlaskit/react-ufo/trace-transition';
import { JiraSoftwareBoardAri } from '@atlassian/ari/jira-software/board';
import { useBoardId } from '@atlassian/jira-board-settings-common/src/common/utils/use-board-id/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { JSErrorPageAsync } from '@atlassian/jira-error-pages/src/async.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { BOARD_SETTINGS_COLUMNS_UFO_NAME } from './common/constants/index.tsx';
import { columnsSettingPageEntryPoint } from './entrypoint.tsx';
import { columnsSettingPageNewEntryPoint } from './ui/columns-settings-page-new/entrypoint.tsx';

export const ColumnSettingsMappingPageAsyncOld = () => {
	const boardId = useBoardId();
	const cloudId = useCloudId();

	const boardAri = JiraSoftwareBoardAri.create({
		siteId: cloudId,
		boardId: String(boardId),
	}).toString();

	const entrypointParams = useMemo(() => ({ boardId: boardAri, cloudId }), [boardAri, cloudId]);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		columnsSettingPageEntryPoint,
		entrypointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
		return () => {
			entryPointActions.unload();
		};
	}, [entryPointActions]);

	useEffect(() => {
		traceUFOTransition(BOARD_SETTINGS_COLUMNS_UFO_NAME);
	}, []);

	return (
		<JiraEntryPointContainer
			id="jira-columns-settings-page"
			packageName="columnsSettings"
			teamName="a4t-pandora"
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={{}}
			fallback={null}
			errorFallback={({ error }) => <JSErrorPageAsync error={error} isFullPage={false} />}
		/>
	);
};

export const ColumnSettingsMappingPageAsyncNew = () => {
	const boardId = useBoardId();
	const cloudId = useCloudId();

	const boardAri = JiraSoftwareBoardAri.create({
		siteId: cloudId,
		boardId: String(boardId),
	}).toString();

	const entrypointParams = useMemo(() => ({ boardId: boardAri, cloudId }), [boardAri, cloudId]);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		columnsSettingPageNewEntryPoint,
		entrypointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
		return () => {
			entryPointActions.unload();
		};
	}, [entryPointActions]);

	useEffect(() => {
		traceUFOTransition(BOARD_SETTINGS_COLUMNS_UFO_NAME);
	}, []);

	return (
		<JiraEntryPointContainer
			id="jira-columns-settings-page"
			packageName="columnsSettings"
			teamName="a4t-pandora"
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={{}}
			fallback={null}
			errorFallback={({ error }) => <JSErrorPageAsync error={error} isFullPage={false} />}
		/>
	);
};

export const ColumnSettingsMappingPageAsync = componentWithFG(
	'csm_and_quick_filter_config_performance',
	ColumnSettingsMappingPageAsyncNew,
	ColumnSettingsMappingPageAsyncOld,
);
