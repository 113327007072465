import React, { useCallback, useMemo, useEffect } from 'react';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import type { ColorItem } from '@atlassian/jira-software-board-settings-query-list-config-common/src/common/types/index.tsx';
import { SuccessFlagWithBoardLink } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/success-flag-with-board-link/index.tsx';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller/src/index.tsx';
import { BOARD_SETTINGS_EDIT_CARD_COLOR_MODAL_ID } from '../../../common/constants/modals.tsx';
import { useEditCardColor } from '../../../services/edit-card-color/index.tsx';
import type { EditCardColorInput } from '../../../services/edit-card-color/types.tsx';
import { useRapidViewId, useIsCMPBoard } from '../../selectors/board/index.tsx';
import { useItemToEdit, useCardColorStrategy } from '../../selectors/colors/index.tsx';
import messages from './messages.tsx';

export const useEditCardColorHandler = () => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();

	const [rapidViewId] = useRapidViewId();
	const [isCMPBoard] = useIsCMPBoard();
	const [cardColorStrategy] = useCardColorStrategy();
	const [itemToEdit, { setItemToEdit, editCardColor }] = useItemToEdit();

	const [isEditModalOpen, { on: openModal, off: closeModal }] = useSwitchModals(
		BOARD_SETTINGS_EDIT_CARD_COLOR_MODAL_ID,
	);

	const {
		commit: commitEditCardColor,
		loading: isSubmitting,
		error,
		resetError,
		queryValidationErrorMessage,
	} = useEditCardColor(rapidViewId);

	const editQueryValidationErrorMessage = useMemo(() => {
		if (queryValidationErrorMessage) {
			return `${queryValidationErrorMessage} ${formatMessage(
				messages.validationErrorInstructionForUpdatingCardColor,
			)}`;
		}
	}, [formatMessage, queryValidationErrorMessage]);

	/** Modal trigger actions */
	const openEditModal = useCallback(
		(colorQueryItem: ColorItem) => {
			setItemToEdit(colorQueryItem);
			openModal();
		},
		[openModal, setItemToEdit],
	);

	const closeEditModal = useCallback(() => {
		setItemToEdit(null);
		closeModal();
	}, [closeModal, setItemToEdit]);

	/** Edit card color modal callbacks */
	const onSubmit = useCallback(
		async (requestData: EditCardColorInput) => {
			const result = await commitEditCardColor(requestData);

			if (result) {
				editCardColor(result);
				showFlag((props) => (
					<SuccessFlagWithBoardLink
						{...props}
						isCMPBoard={isCMPBoard}
						title={formatMessage(messages.editCardColorSuccess)}
					/>
				));
			}

			return result;
		},
		[commitEditCardColor, editCardColor, formatMessage, isCMPBoard, showFlag],
	);

	const onEditSubmit = useCallback(
		async ({ id, color, query: value }: Omit<ColorItem, 'name'>) => {
			const result = await onSubmit({
				id: Number(id),
				color,
				value,
				strategy: cardColorStrategy,
			});

			result && closeEditModal();
		},
		[cardColorStrategy, closeEditModal, onSubmit],
	);

	/** Inline edit card color callback */
	const onInlineEditSubmit = useCallback(
		async (itemId: ColorItem['id'], nextColor: ColorItem['color']) => {
			await onSubmit({
				id: Number(itemId),
				color: nextColor,
			});
		},
		[onSubmit],
	);

	useEffect(() => {
		if (error && !queryValidationErrorMessage) {
			closeEditModal();
		}
	}, [error, closeEditModal, queryValidationErrorMessage]);

	useEffect(() => {
		isEditModalOpen ? resetError() : setItemToEdit(null);
	}, [isEditModalOpen, resetError, setItemToEdit]);

	return {
		openEditModal,
		onEditSubmit,
		onInlineEditSubmit,
		isSubmitting,
		closeEditModal,
		editQueryValidationErrorMessage,
		itemToEdit,
	};
};
