import React, { useCallback, useEffect, useMemo } from 'react';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import type { ColorItem } from '@atlassian/jira-software-board-settings-query-list-config-common/src/common/types/index.tsx';
import { SuccessFlagWithBoardLink } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/success-flag-with-board-link/index.tsx';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller/src/index.tsx';
import { BOARD_SETTINGS_CREATE_CARD_COLOR_MODAL_ID } from '../../../common/constants/modals.tsx';
import { useAddCardColor } from '../../../services/add-card-color/index.tsx';
import { useRapidViewId, useIsCMPBoard } from '../../selectors/board/index.tsx';
import { useCardColorStrategy, useCardColors } from '../../selectors/colors/index.tsx';
import messages from './messages.tsx';

export const useAddCardColorHandler = () => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();

	const [rapidViewId] = useRapidViewId();
	const [isCMPBoard] = useIsCMPBoard();
	const [cardColorStrategy] = useCardColorStrategy();
	const [, { addCardColor }] = useCardColors();
	const [isCreateModalOpen, { off: closeCreateModal }] = useSwitchModals(
		BOARD_SETTINGS_CREATE_CARD_COLOR_MODAL_ID,
	);

	const {
		commit: commitAddCardColor,
		loading: isSubmitting,
		error,
		resetError,
		queryValidationErrorMessage,
	} = useAddCardColor(rapidViewId);

	const createQueryValidationErrorMessage = useMemo(() => {
		if (queryValidationErrorMessage) {
			return `${queryValidationErrorMessage} ${formatMessage(
				messages.validationErrorInstructionForCreatingCardColor,
			)}`;
		}
	}, [formatMessage, queryValidationErrorMessage]);

	const onAddSubmit = useCallback(
		async ({ color, query }: Omit<ColorItem, 'id'>) => {
			const result = await commitAddCardColor({
				color,
				strategy: cardColorStrategy,
				value: query,
			});

			if (result) {
				addCardColor(result);
				showFlag((props) => (
					<SuccessFlagWithBoardLink
						{...props}
						isCMPBoard={isCMPBoard}
						title={formatMessage(messages.createCardColorSuccess)}
					/>
				));
				closeCreateModal();
			}
		},
		[
			addCardColor,
			cardColorStrategy,
			closeCreateModal,
			commitAddCardColor,
			formatMessage,
			isCMPBoard,
			showFlag,
		],
	);

	useEffect(() => {
		if (error && !queryValidationErrorMessage) {
			closeCreateModal();
		}
	}, [closeCreateModal, error, queryValidationErrorMessage]);

	useEffect(() => {
		isCreateModalOpen && resetError();
	}, [isCreateModalOpen, resetError]);

	return {
		onAddSubmit,
		isSubmitting,
		createQueryValidationErrorMessage,
	};
};
